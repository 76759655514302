import { AfterViewInit, Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { maxOver, minOver, MultiAggregator, noAgg, SingleAggregator, weightedAverage } from '../../services/aggregation';
import {
	AttrPath,
	BasicFilterExpression,
	CijferMeasure,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { att } from '../../services/measures';
import { ColumnDef } from '../../shared/components/table/table/table.model';
import { DRIE_SCHOOLJAREN_EXCL_2020, getSchooljaarRangeString, generateCssClassForString } from '@cumlaude/shared-utils';
import { FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { get, initial, last, memoize, range } from 'lodash-es';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Observable } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { BarInfo } from '../../services/stacked-bars';
import { formatNumber } from '@angular/common';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { Axis } from '../../services/axis';
import { DashboardVariant } from '../../services/weergave-opties';
import { ExamenStatus } from '@cumlaude/metadata';
import { indicatorOverOpties, SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipType } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { OnderwijsresultatenUitsluitenFilterComponent } from '../../onderwijsresultaten-uitsluiten-filter/onderwijsresultaten-uitsluiten-filter.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

const permanentTable: AttrPath[] = [['cf_fk_ilt', 'ilt_nm_niveau']];

export interface CijferSeCeI extends Attributes {
	cf_nr_cijfer: number;
	cf_nr_cijfer_ce: number;
	cf_fun_cijferverschil_se_ce: number;
	cf_nr_leerlingen: number;
	/** Weergave Verschil: Histogram met aantallen per afgerond verschil */
	cf_nr_verschil_minus_3_75: number; // < -3,75
	cf_nr_verschil_minus_3_5: number;
	cf_nr_verschil_minus_3: number;
	cf_nr_verschil_minus_2_5: number;
	cf_nr_verschil_minus_2: number;
	cf_nr_verschil_minus_1_5: number;
	cf_nr_verschil_minus_1: number;
	cf_nr_verschil_0: number;
	cf_nr_verschil_0_5: number;
	cf_nr_verschil_1: number;
	cf_nr_verschil_1_5: number;
	cf_nr_verschil_2: number;
	cf_nr_verschil_2_5: number;
	cf_nr_verschil_3: number;
	cf_nr_verschil_3_5: number;
	cf_nr_verschil_3_75: number; // >= +3,75
	/** Weergave CE of SE: Histogram met aantallen per afgerond cijfer */
	cf_nr_aantal_0: number;
	cf_nr_aantal_1: number;
	cf_nr_aantal_2: number;
	cf_nr_aantal_3: number;
	cf_nr_aantal_4: number;
	cf_nr_aantal_5: number;
	cf_nr_aantal_6: number;
	cf_nr_aantal_7: number;
	cf_nr_aantal_8: number;
	cf_nr_aantal_9: number;
	cf_nr_aantal_10: number;
}

export interface CijferSeCeA extends Attributes {
	cijferVerschilSeCe: number | null;
	cijferSe: number | null;
	cijferCe: number | null;
	verschilAfgerond: number | null;
	cijferAfgerond: number;
	minVerschil: number;
	maxVerschil: number;
	leerlingen: number | null;
}

// aantal CE-cijfers voor het betreffende schooljaar voor de betreffende bekostigingings-BRIN moet >= 30 zijn
const uitsluitenFilter = new BasicFilterExpression(['cf_fk_or_cvcl', 'or_q_vakken'], 30, '>=');

const permanentFilters: FilterExpression[] = [
	new BasicFilterExpression(['cf_is_diplomavak'], 1),
	// Combinatiecijfervakken HAVO/VWO niet tonen, beroepsgerichte vakken VMBO wel
	new CompoundFilterExpression(
		[
			new BasicFilterExpression(['cf_is_combinatiecijfervak'], 0),
			new BasicFilterExpression(['cf_fk_ilt', 'ilt_nm_niveau'], ['HAVO', 'VWO'], 'not in'),
		],
		'or'
	),
	new BasicFilterExpression(['cf_nm_kolomtype'], 'SE-cijfer'),
	new BasicFilterExpression(['cf_map_examenstatus_met_prognose'], [ExamenStatus.GESLAAGD, ExamenStatus.AFGEWEZEN], 'in'),
	new CompoundFilterExpression(
		[new BasicFilterExpression(['cf_fk_vk', 'vk_co_vak'], 1107, '<>'), new BasicFilterExpression(['cf_nm_schooljaar'], '2015/2016', '<')],
		'or'
	),
];

@Component({
	selector: 'app-verschil-se-ce',
	templateUrl: './verschil-se-ce.component.html',
	styleUrls: ['./verschil-se-ce.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		SchooljaarIndicatorOverComponent,
		OnderwijsresultatenUitsluitenFilterComponent,
		BarchartTableComponent,
	],
})
export class VerschilSeCeComponent extends BarchartTableConfig<CijferSeCeI, CijferSeCeA> implements AfterViewInit, OnInit {
	table: AttrPath[] = permanentTable;

	defaultGroup: AttrPath = ['cf_fk_vk', 'vk_nm_kernvak'];

	fixedBeforeGroups = 1;

	flexibleMaxGroups = 1;

	flexibleGroupsRemovable = false;

	selectedGroup: AttrPath = this.defaultGroup;

	availableGroups: AttrPath[] = [
		['cf_fk_vk', 'vk_nm_kernvak'],
		['cf_nm_vak'],
		['cf_fk_vk', 'vk_nm_vak'],
		['cf_nm_lesgroep_docenten'],
		['cf_fks_mw', 'mw_nm_medewerker'],
	];

	initialFilters: FilterName[] = [
		'x_onderwijsresultaten_cf_schooljaar',
		'cf_fk_br_vest.br_co_brin',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nm_vak',
		'cf_fk_vk.vk_nm_kernvak',
		'cf_nm_excijf_uitzondering',
		'x_cijfer_metzonder_ce',
	];

	filterExpressions?: FilterExpression[];

	variant!: DashboardVariant;

	schooljaar?: string;

	aantalJaren = DRIE_SCHOOLJAREN_EXCL_2020;

	uitsluiten!: boolean;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	protected subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => this.setGroups(groups)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe('or-uitsluiten').subscribe((meetellen) => (this.uitsluiten = meetellen))
		);
	}

	protected setGroups(groups: AttrPath[] | undefined) {
		this.selectedGroup = groups ? groups[0] : this.defaultGroup;
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.filterService
				.observe('x_onderwijsresultaten_cf_schooljaar')
				.pipe(delay(0))
				.subscribe(([schooljaar, aantalJaren]) => {
					this.schooljaar = schooljaar;
					this.aantalJaren = aantalJaren;
				})
		);
	}

	protected getFixedAfterGroups(): number {
		return this.variant === DashboardVariant.ACTUEEL ? 0 : 1;
	}

	getAlternatingGroupLevel(): number {
		return this.variant === DashboardVariant.ACTUEEL ? 1 : 2;
	}

	factTable = FactTable.cijfers;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijfersData({ ...options, m: [CijferMeasure.SE_CE], r: this.getRollupLevels(options) });
	}

	protected getRollupLevels({ g }: DataOptions): number[] | undefined {
		return g?.length ? [0, g.length - 1] : undefined;
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getCijfersExportData(options);
	}

	// memoize, otherwise new array keeps triggering change detection
	getGroups = memoize(VerschilSeCeComponent._getGroups, (a, b) => JSON.stringify([a, b]));

	private static _getGroups(variant: DashboardVariant, selectedGroup: AttrPath): AttrPath[] {
		return variant === DashboardVariant.ACTUEEL ? [selectedGroup] : [selectedGroup, ['cf_nm_schooljaar']];
	}

	protected multiAggregators: MultiAggregator<keyof CijferSeCeA, CijferSeCeI, CijferSeCeA, number | null>[] = [
		weightedAverage('cijferSe', 'cf_nr_cijfer'),
		weightedAverage('cijferCe', 'cf_nr_cijfer_ce'),
	];

	protected singleAggregators: Partial<{ [ai in keyof CijferSeCeA]: SingleAggregator<CijferSeCeI, CijferSeCeA[ai]> }> = {
		leerlingen: noAgg('cf_nr_leerlingen'),
		cijferVerschilSeCe: noAgg('cf_fun_cijferverschil_se_ce'),
		minVerschil: minOver('cf_fun_cijferverschil_se_ce'),
		maxVerschil: maxOver('cf_fun_cijferverschil_se_ce'),
	};

	createMeasureColumns(context: DashboardContext<CijferSeCeI, CijferSeCeA, VerschilSeCeComponent>): ColumnDef<DataRow<CijferSeCeA>>[] {
		return [
			createMeasureColumn('SE-CE', att('cijferVerschilSeCe'), { context, format: '+1.2-2' }),
			createMeasureColumn('SE', att('cijferSe'), { context, format: '1.2-2' }),
			createMeasureColumn('CE', att('cijferCe'), { context, format: '1.2-2' }),
			this.createAantalColumn(context),
		];
	}

	protected createAantalColumn(context: DashboardContext<CijferSeCeI, CijferSeCeA, VerschilSeCeComponent>): ColumnDef<DataRow<CijferSeCeA>> {
		return createMeasureColumn('Cijfers', att('count_records'), { context });
	}

	partitionBarData(rowRoot: Level<CijferSeCeA, number[]>): Path<CijferSeCeA, number[]>[][] {
		const path = rowRoot.r[0];
		if (!path) return [];
		const a = getLeafA(path);
		// genereer records met verschilAfgerond van -3.75, -3.5 t/m 3.5, 3.75
		const verschillen = [-3.75, ...range(-3.5, 4, 0.5), 3.75];
		return [verschillen.map((verschilAfgerond) => [...initial(path), { ...last(path)!, a: { ...a, verschilAfgerond } }])];
	}

	makeBar(
		attrs: CijferSeCeI,
		path: Path<CijferSeCeA, number[]>,
		context: DashboardContext<CijferSeCeI, CijferSeCeA, VerschilSeCeComponent>
	): BarInfo {
		const a = last(path)!.a;
		const verschilAfgerond = a.verschilAfgerond;
		const linkData = this.createLinkData(path, context);

		if (verschilAfgerond === null) return { size: 0, linkData };
		const verschilString = verschilAfgerond.toString().replace('-', 'minus_').replace('.', '_');
		const size: number = get(attrs, 'cf_nr_verschil_' + verschilString, 0);
		const className = generateCssClassForString(verschilString);

		return {
			size,
			linkData,
			className,
			tooltip: this.createTooltip(a, size),
		};
	}

	createTooltip({ verschilAfgerond }: CijferSeCeA, size: number): TooltipType {
		let tooltip = 'Aantal ';
		if (verschilAfgerond === null) {
			tooltip += 'onbekend: ';
		} else if (verschilAfgerond === 0) {
			tooltip += 'SE-cijfers gelijk aan het bijbehorende CE-cijfer: ';
		} else {
			const toolTipMeerDan = verschilAfgerond === -3.75 || verschilAfgerond === 3.75 ? 'meer dan ' : '';
			const formattedNumber = formatNumber(Math.abs(verschilAfgerond), 'nl-NL', '1.0-2');
			const hogerOfLager = verschilAfgerond < 0 ? 'lager' : 'hoger';

			tooltip += `SE-cijfers ${toolTipMeerDan}${formattedNumber} punt${
				Math.abs(verschilAfgerond) >= 2 ? 'en' : ''
			} ${hogerOfLager} dan het bijbehorende CE-cijfer: `;
		}
		tooltip += `${size}`;
		return tooltip;
	}

	createLinkData(path: Path<CijferSeCeA, number[]>, context: DashboardContext<CijferSeCeI, CijferSeCeA, VerschilSeCeComponent>): Partial<LinkData> {
		const groups = this.variant === DashboardVariant.ACTUEEL ? [...context.groupNames] : [...context.groupNames, context.subgroupNames[0]];

		return this.createZoomLinkData(
			['cf_fk_vk.vk_nm_kernvak', 'cf_nm_vak', 'cf_nm_lesgroep_docenten'],
			groups,
			path,
			{
				...super.createLinkData(path, context),
				dashboard: '/details/leerling/cijferlijst',
				dataProvider: 'cijfers',
			},
			{ singleGroup: true }
		);
	}

	getBarchartQty(path: Path<CijferSeCeA, number[]>): number | null {
		return getLeafA(path).cijferVerschilSeCe ?? 0;
	}

	protected areas = [
		{ qty: -1, className: 'dark' },
		{ qty: -0.5, className: 'light' },
		{ qty: 0, className: 'none' },
		{ qty: 0.5, className: 'none' },
		{ qty: 1, className: 'light' },
		{ className: 'dark' },
	];

	createXAxis(context: DashboardContext<CijferSeCeI, CijferSeCeA, VerschilSeCeComponent>): Axis {
		const min = Math.min(context.dataRoot!.a.minVerschil ?? 0, 0);
		const max = Math.max(context.dataRoot!.a.maxVerschil ?? 0, 0);
		return { min, max, areas: this.areas, ticks: this.createTicks(min, max) };
	}

	private createTicks(min: number, max: number) {
		// we willen maximaal 5 ticks (het exacte aantal hangt af van afronding)
		const nSteps = 5;
		// het bereik is min tot max
		// als min positief is of max negatief, wordt het bereik opgerekt zodat 0 er deel van uitmaakt
		const delta = Math.max(max, 0) - Math.min(min, 0);
		// de gewenste stapgrootte is (delta / nSeps), maar dan afgerond
		// zoek een afronding die geschikt is voor dit bereik
		const b = Math.pow(10, Math.round(Math.log10(delta / nSteps))) / 2;
		// rond de stapgrootte af naar het volgende b-tal
		const step = b * Math.ceil(delta / nSteps / b);

		const qties: number[] = [];
		// bij een negatieve min gaan we terug vanaf 0 naar min, maar dan omgekeerd
		if (min < 0) qties.push(...range(-step, min, -step).reverse());
		// we gaan altijd vanaf 0 naar max
		qties.push(...range(0, max, step));

		return qties.map((qty) => ({
			qty,
			label: (qty > 0 ? '+' : '') + formatNumber(qty, 'nl-NL', '1.2-2'),
			// alleen lijnen voor marks die niet samenvallen met een shade of de y-as
			showLine: ![-1, -0.5, 0, 0.5, 1].includes(qty),
		}));
	}

	getSchooljarenCaption() {
		return this.schooljaar ? getSchooljaarRangeString(this.schooljaar, this.aantalJaren) : '';
	}

	getPermanentExpressions = memoize(this._getPermanentExpressions, JSON.stringify);

	private _getPermanentExpressions(uitsluiten: boolean) {
		return uitsluiten ? [...permanentFilters, uitsluitenFilter] : permanentFilters;
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			{ label: 'Indicator over', value: indicatorOverOpties[this.aantalJaren] },
			{ label: 'Meetellende vestigingen', value: this.uitsluiten ? 'Minimaal 30 cijfers' : 'Alle' },
		];
	}
}
