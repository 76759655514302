import { Vaardigheid } from '@cumlaude/metadata';
import { Categorie, modifyAtt } from './exportable';
import { leerling_instroom_uitstroom, leerling_leerling, leerling_vervolgopleiding, leerling_vooropleiding } from './exportable-leerling';
import { loopbaan_instroom_uitstroom, loopbaan_kenmerk, loopbaan_plaatsing, opleiding_kenmerk, opleiding_opleiding } from './exportable-loopbaan';

const basisvaardigheden_basisvaardigheden: Categorie = {
	label: 'Basisvaardigheden',
	atts: [
		{ att: 'bv_nm_vaardigheid', example: Object.values(Vaardigheid).join(', ') },
		{ att: 'bv_nm_aanbieder', example: 'CITO, JIJ, DIA' },
		{ att: 'bv_nm_toets', example: 'Toets 0 CITO Volgsysteem VO HV, Diatekst versie A, Nederlands Lezen 3F - 1, ...' },
		{ att: 'bv_nm_toetsmoment', example: 'T0, T1, ...' },
		{ att: 'bv_nm_toetsniveau', example: 'VMBO b, HAVO, ...' },
		{ att: 'bv_nm_referentieniveau', example: '1F, 2F, >=3F, Op weg naar 3F, ...' },
		{ att: 'bv_nr_referentieniveau', example: '0, 1, 2, 3, 4' },
		{ att: 'bv_nr_verbetering', example: '0, 1, 2, -1, ...' },
		{ att: 'bv_d_afname', example: '14-09-2023' },
	],
};

const basisvaardigheden_doorstroom: Categorie = {
	label: 'Doorstroom',
	atts: [
		{ att: 'bv_fk_lb.lb_is_bevorderd', example: 'Ja, Nee' },
		{ att: 'bv_fk_lb.lb_is_succesvol', example: 'Ja, Nee' },
	],
};

const basisvaardigheden_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		...loopbaan_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'bv_fk_lb')),
		...leerling_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'bv_fk_ll')),
	],
};

const basisvaardigheden_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [...loopbaan_kenmerk.atts.map((att) => modifyAtt(att, 'bv_fk_lb')), ...opleiding_kenmerk.atts.map((att) => modifyAtt(att, 'bv_fk_ilt'))],
};

const basisvaardigheden_leerling: Categorie = {
	label: 'Leerling',
	atts: leerling_leerling.atts.map((att) => modifyAtt(att, 'bv_fk_ll')),
};

const basisvaardigheden_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [
		...opleiding_opleiding.atts.map((att) => modifyAtt(att, 'bv_fk_ilt')),
		{ att: 'bv_fk_lb.lb_nm_opleiding', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'bv_nr_leerjaar', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'bv_fk_lb.lb_nm_klas', example: '1V2, 2B3, 3H4, ...' },
		{ att: 'bv_nm_leerfase', example: 'HAVO 3, VMBO b/k 1, OND 2' },
		{ att: 'bv_fk_lb.lb_abb_leerfase', example: 'h3, bk1, lj2' },
		{ att: 'bv_fk_lb.lb_nm_lichting', example: 'VWO 2020-2023, VMBO Basisberoepsgerichte leerweg 2021-2023, ...' },
		{ att: 'bv_fk_lb.lb_nm_onderwijssoort', example: 'Praktijkonderwijs, VMBO Theoretische leerweg tweetalig, Gymnasium, ...' },
	],
};

const basisvaardigheden_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'bv_fk_ll.ll_nm_niveau_lj3', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'bv_fun_is_plaatsing_peildatum_1april', example: 'Ja, Nee' },
		{ att: 'bv_fun_is_plaatsing_peildatum_1feb', example: 'Ja, Nee' },
		{ att: 'bv_fun_is_plaatsing_peildatum_1jan', example: 'Ja, Nee' },
		{ att: 'bv_fun_is_plaatsing_peildatum_1juli', example: 'Ja, Nee' },
		{ att: 'bv_fun_is_plaatsing_peildatum_1okt', example: 'Ja, Nee' },
		...loopbaan_plaatsing.atts.map((att) => modifyAtt(att, 'bv_fk_lb')),
	],
};

const basisvaardigheden_prestatieanalyse: Categorie = {
	label: 'Prestatieanalyse',
	atts: [{ att: 'bv_fk_lb.lb_nm_uitstroomprofiel_vso', example: 'Vervolgonderwijs, Arbeidsmarkt, Dagbesteding' }],
};

const basisvaardigheden_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'bv_fk_lb.lb_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'bv_fk_inst.inst_nm_instelling', example: 'CumLaude College' },
		{ att: 'bv_fk_lb.lb_co_brin', example: '01XX' },
	],
};

const basisvaardigheden_vervolgopleiding: Categorie = {
	label: 'Vervolgopleiding',
	atts: [
		{ att: 'bv_fk_lb.lb_co_brin_svh', example: '02AA' },
		{ att: 'bv_fk_lb.lb_nr_leerjaar_svb', example: '1, 2, 3, 4, 5, 6' },
		...leerling_vervolgopleiding.atts.map((att) => modifyAtt(att, 'bv_fk_ll')),
	],
};

const basisvaardigheden_vooropleiding: Categorie = {
	label: 'Vooropleiding',
	atts: [
		{ att: 'bv_fun_basisschooladvies_duo', example: 'PrO, VMBO b, VMBO k, VMBO (g)t, HAVO, VWO, ...' },
		...leerling_vooropleiding.atts.map((att) => modifyAtt(att, 'bv_fk_ll')),
	],
};

export const basisvaardigheden_all = [
	basisvaardigheden_basisvaardigheden,
	basisvaardigheden_doorstroom,
	basisvaardigheden_instroom_uitstroom,
	basisvaardigheden_kenmerk,
	basisvaardigheden_leerling,
	basisvaardigheden_opleiding,
	basisvaardigheden_plaatsing,
	basisvaardigheden_prestatieanalyse,
	basisvaardigheden_school,
	basisvaardigheden_vervolgopleiding,
	basisvaardigheden_vooropleiding,
];
