/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ExamencijfersUitzondering {
  REGULIER_EXAMENCIJFER = 'Regulier examencijfer',
  ASSISTENTENOPLEIDING_ENTREEOPLEIDING = 'Assistentenopleiding/Entreeopleiding',
  VAVO_OPLEIDING = 'VAVO-opleiding',
  VAKMANSCHAP_TECHNOLOGIE_ROUTE = 'Vakmanschap/technologie-route',
  NIET_BEKOSTIGBAAR = 'Niet bekostigbaar'
}
