<span class="tab-button svg-chevron-left" [class.visible]="showLeft" (click)="scrollLeft()"></span>
<section #viewport>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/dashboard/mijn")) {
			<a routerLink="/dashboard/mijn" routerLinkActive="active">Mijn Dashboard</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/onderwijsresultaten/onderwijsresultaten")) {
			<a
				routerLink="/onderwijsresultaten/onderwijsresultaten"
				[queryParams]="removeFromQp"
				queryParamsHandling="merge"
				routerLinkActive="active"
			>
				Onderwijsresultaten
			</a>
		}
		@if (isAllowedForRol("/onderwijsresultaten/onderwijspositie")) {
			<a routerLink="/onderwijsresultaten/onderwijspositie" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Onderwijspositie t.o.v. advies PO
			</a>
		}
		@if (isAllowedForRol("/onderwijsresultaten/onderbouwsnelheid")) {
			<a routerLink="/onderwijsresultaten/onderbouwsnelheid" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Onderbouwsnelheid
			</a>
		}
		@if (isAllowedForRol("/onderwijsresultaten/bovenbouwsucces")) {
			<a routerLink="/onderwijsresultaten/bovenbouwsucces" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Bovenbouwsucces
			</a>
		}
		@if (isAllowedForRol("/onderwijsresultaten/examencijfers")) {
			<a routerLink="/onderwijsresultaten/examencijfers" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Examencijfers
			</a>
		}
		@if (isAllowedForRol("/onderwijsresultaten/verschil-se-ce")) {
			<a routerLink="/onderwijsresultaten/verschil-se-ce" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Verschil SE-CE
			</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/prestatieanalyse/prestatieanalyse")) {
			<a routerLink="/prestatieanalyse/prestatieanalyse" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Prestatieanalyse
			</a>
		}
		@if (isAllowedForRol("/prestatieanalyse/einduitstroom")) {
			<a routerLink="/prestatieanalyse/einduitstroom" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Einduitstroom
			</a>
		}
		@if (isAllowedForRol("/prestatieanalyse/uitstroom-iq")) {
			<a routerLink="/prestatieanalyse/uitstroom-iq" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Uitstroom i.r.t. het IQ
			</a>
		}
		@if (isAllowedForRol("/prestatieanalyse/tussentijdse-uitstroom")) {
			<a
				routerLink="/prestatieanalyse/tussentijdse-uitstroom"
				[queryParams]="removeFromQp"
				queryParamsHandling="merge"
				routerLinkActive="active"
			>
				Tussentijdse uitstroom
			</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/cijfers/overzicht")) {
			<a routerLink="/cijfers/overzicht" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Overzicht </a>
		}
		@if (isAllowedForRol("/cijfers/docent")) {
			<a routerLink="/cijfers/docent" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Docent </a>
		}
		@if (isAllowedForRol("/cijfers/toets")) {
			<a routerLink="/cijfers/toets" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Toets </a>
		}
		@if (isAllowedForRol("/cijfers/toets-details")) {
			<a routerLink="/cijfers/toets-details" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Toets details
			</a>
		}
		@if (isAllowedForRol("/cijfers/se-ce")) {
			<a routerLink="/cijfers/se-ce" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> SE-CE </a>
		}
		@if (isAllowedForRol("/cijfers/cijferlijst")) {
			<a routerLink="/cijfers/cijferlijst" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Cijferlijst </a>
		}
		@if (isAllowedForRol("/cijfers/examens")) {
			<a routerLink="/cijfers/examens" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Examens </a>
		}
	</div>
	<div routerLinkActive="visible">
		@for (vaardigheid of vaardigheden; track vaardigheid) {
			@if (isAllowedForRol(getBasisvaardigheidUrl(vaardigheid))) {
				<a [routerLink]="getBasisvaardigheidUrl(vaardigheid)" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> {{ vaardigheid }} </a>
			}
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/doorstroom/idu/doorstroom")) {
			<a routerLink="/doorstroom/idu/doorstroom" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Doorstroom
			</a>
		}
		@if (isAllowedForRol("/doorstroom/idu/matrix")) {
			<a routerLink="/doorstroom/idu/matrix" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Matrix </a>
		}
		@if (isAllowedForRol("/doorstroom/idu/geslaagd")) {
			<a routerLink="/doorstroom/idu/geslaagd" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Geslaagd </a>
		}
		@if (isAllowedForRol("/doorstroom/idu/instroom")) {
			<a routerLink="/doorstroom/idu/instroom" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Instroom </a>
		}
		@if (isAllowedForRol("/doorstroom/idu/uitstroom")) {
			<a routerLink="/doorstroom/idu/uitstroom" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Uitstroom
			</a>
		}
		@if (isAllowedForRol("/doorstroom/idu/overgang")) {
			<a routerLink="/doorstroom/idu/overgang" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Overgang </a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/doorstroom/advies/plaatsing")) {
			<a routerLink="/doorstroom/advies/plaatsing" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Plaatsing / Advies
			</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/doorstroom/cohort/rendement")) {
			<a routerLink="/doorstroom/cohort/rendement" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Cohortrendement
			</a>
		}
		@if (isAllowedForRol("/doorstroom/cohort/details")) {
			<a routerLink="/doorstroom/cohort/details" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Cohortdetails
			</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/afwezigheid/overzicht")) {
			<a routerLink="/afwezigheid/overzicht" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Overzicht </a>
		}
		@if (isAllowedForRol("/afwezigheid/lesregistraties")) {
			<a routerLink="/afwezigheid/lesregistraties" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Lesregistraties
			</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/leerling/leerling")) {
			<a routerLink="/leerling/leerling" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Leerling </a>
		}
		@if (isAllowedForRol("/leerling/kenmerk")) {
			<a routerLink="/leerling/kenmerk" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Kenmerk </a>
		}
		@if (isAllowedForRol("/leerling/vakken")) {
			<a routerLink="/leerling/vakken" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active"> Vakken </a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/details/leerling/doorstroom")) {
			<a routerLink="/details/leerling/doorstroom" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active">
				Doorstroom
			</a>
		}
		@if (isAllowedForRol("/details/leerling/basisvaardigheden")) {
			<a routerLink="/details/leerling/basisvaardigheden" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active">
				Basisvaardigheden
			</a>
		}
		@if (isAllowedForRol("/details/leerling/cijferlijst")) {
			<a routerLink="/details/leerling/cijferlijst" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active">
				Cijferlijst
			</a>
		}
		@if (isAllowedForRol("/details/leerling/afwezigheid")) {
			<a routerLink="/details/leerling/afwezigheid" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active">
				Afwezigheid
			</a>
		}
		@if (isAllowedForRol("/details/leerling/afwezigheidsredenen")) {
			<a routerLink="/details/leerling/afwezigheidsredenen" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active">
				Afwezigheidsredenen
			</a>
		}
		@if (isAllowedForRol("/details/leerling/lesregistraties")) {
			<a routerLink="/details/leerling/lesregistraties" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active">
				Lesregistraties
			</a>
		}
		@if (isAllowedForRol("/details/leerling/lesregistratieredenen")) {
			<a
				routerLink="/details/leerling/lesregistratieredenen"
				[queryParams]="keepSelectie"
				queryParamsHandling="merge"
				routerLinkActive="active"
			>
				Lesregistratieredenen
			</a>
		}
		@if (isAllowedForRol("/details/leerling/vakken")) {
			<a routerLink="/details/leerling/vakken" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active"> Vakken </a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/details/docent/cijfers")) {
			<a routerLink="/details/docent/cijfers" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active"> Cijfers </a>
		}
		@if (isAllowedForRol("/details/docent/toets")) {
			<a routerLink="/details/docent/toets" [queryParams]="keepSelectie" queryParamsHandling="merge" routerLinkActive="active"> Toets </a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/details/uitzondering/doorstroom")) {
			<a routerLink="/details/uitzondering/doorstroom" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Doorstroom
			</a>
		}
		@if (isAllowedForRol("/details/uitzondering/onderwijspositie")) {
			<a routerLink="/details/uitzondering/onderwijspositie" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Onderwijspositie
			</a>
		}
		@if (isAllowedForRol("/details/uitzondering/onderbouwsnelheid")) {
			<a
				routerLink="/details/uitzondering/onderbouwsnelheid"
				[queryParams]="removeFromQp"
				queryParamsHandling="merge"
				routerLinkActive="active"
			>
				Onderbouwsnelheid
			</a>
		}
		@if (isAllowedForRol("/details/uitzondering/bovenbouwsucces")) {
			<a routerLink="/details/uitzondering/bovenbouwsucces" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Bovenbouwsucces
			</a>
		}
		@if (isAllowedForRol("/details/uitzondering/einduitstroom")) {
			<a routerLink="/details/uitzondering/einduitstroom" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Einduitstroom
			</a>
		}
		@if (isAllowedForRol("/details/uitzondering/uitstroom-iq")) {
			<a routerLink="/details/uitzondering/uitstroom-iq" [queryParams]="removeFromQp" queryParamsHandling="merge" routerLinkActive="active">
				Uitstroom i.r.t. het IQ
			</a>
		}
		@if (isAllowedForRol("/details/uitzondering/tussentijdse-uitstroom")) {
			<a
				routerLink="/details/uitzondering/tussentijdse-uitstroom"
				[queryParams]="removeFromQp"
				queryParamsHandling="merge"
				routerLinkActive="active"
			>
				Tussentijdse uitstroom
			</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/beheer/rollen/school")) {
			<a routerLink="/beheer/rollen/school" routerLinkActive="active">School</a>
		}
		@if (isAllowedForRol("/beheer/rollen/team")) {
			<a routerLink="/beheer/rollen/team" routerLinkActive="active">Team</a>
		}
		@if (isAllowedForRol("/beheer/rollen/sectie")) {
			<a routerLink="/beheer/rollen/sectie" routerLinkActive="active">Sectie</a>
		}
		@if (isAllowedForRol("/beheer/rollen/docent")) {
			<a routerLink="/beheer/rollen/docent" routerLinkActive="active">Docent</a>
		}
		@if (isAllowedForRol("/beheer/rollen/mentor")) {
			<a routerLink="/beheer/rollen/mentor" routerLinkActive="active">Mentor</a>
		}
		@if (isAllowedForRol("/beheer/rollen/bestuur")) {
			<a routerLink="/beheer/rollen/bestuur" routerLinkActive="active">Bestuur</a>
		}
	</div>
	<div routerLinkActive="visible">
		@if (isAllowedForRol("/beheer/cijferinstellingen/vakken_uitsluiten")) {
			<a routerLink="/beheer/cijferinstellingen/vakken_uitsluiten" routerLinkActive="active"> Vakken </a>
		}
		@if (isAllowedForRol("/beheer/cijferinstellingen/cijferkolommen")) {
			<a routerLink="/beheer/cijferinstellingen/cijferkolommen" routerLinkActive="active"> Cijferkolommen </a>
		}
		@if (isAllowedForRol("/beheer/cijferinstellingen/cijferkolommen-details")) {
			<a routerLink="/beheer/cijferinstellingen/cijferkolommen-details" routerLinkActive="active"> Cijferkolommen details </a>
		}
	</div>
	<div routerLinkActive="visible">
		@for (vaardigheid of vaardigheden; track vaardigheid) {
			@if (isAllowedForRol(getBasisvaardighedenBeheerUrl(vaardigheid))) {
				<a [routerLink]="getBasisvaardighedenBeheerUrl(vaardigheid)" routerLinkActive="active"> {{ vaardigheid }} </a>
			}
		}
	</div>
</section>
<span class="tab-button svg-chevron-right" [class.visible]="showRight" (click)="scrollRight()"></span>
<span class="tab-button svg-share" [class.visible]="shareVisible" (click)="sharePagina()"></span>
<div class="tab-button spinner">
	@if (showExportSpinner$ | async) {
		<app-css-spinner></app-css-spinner>
	}
</div>
<span class="tab-button svg-export" [class.visible]="exportVisible" [class.hidden]="showExportSpinner$ | async" (click)="exportClicked.emit()"></span>
