import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DataService, QueryFilterExpression } from '../../services/data.service';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { ColumnDef, createColumnDef, createModel, TableModel } from '../../shared/components/table/table/table.model';
import { bufferWhen, catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ActionCellComponent, ActionCellProperties } from '../../shared/components/table/cells/action-cell/action-cell.component';
import { CheckboxCellComponent } from '../../shared/components/table/cells/checkbox-cell/checkbox-cell.component';
import { AccountsService } from '../../services/accounts.service';
import { EditAccountDialogComponent } from '../../dialogs/account/edit-account-dialog/edit-account-dialog.component';
import {
	InterneAccountsToevoegenDialogComponent,
	RCumLaudeAccountX,
} from '../../dialogs/account/interne-accounts-toevoegen-dialog/interne-accounts-toevoegen-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageEnum } from '@cumlaude/metadata';
import { omit } from 'lodash-es';
import { RestService } from '@cumlaude/shared-services';
import { AccountRow, AccountScreen } from '../account-screen';
import { Dialog } from '@angular/cdk/dialog';
import { BugsnagService } from '@cumlaude/bugsnag';
import { AsyncPipe } from '@angular/common';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { ButtonComponent, ToggleButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { InstellingBron, RCumLaudeAccount, RCumLaudeAccountAdditionalObjectKey, RInstelling, RRol } from '@cumlaude/service-contract';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { ExportColumnDef, ExportTable } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Orientation } from '../../services/export.service';
import { saveAs } from 'file-saver';
import { getTimestamp, prettyPeopleList } from '@cumlaude/shared-utils';
import { CssSpinnerComponent } from '../../layout/css-spinner/css-spinner.component';

export type MedewerkerAccountInfo = {
	mw_nm_achternaam: string;
	mw_nm_voorvoegsel: string;
	mw_nm_roepnaam: string;
	mw_abb_medewerker: string;
	mw_co_externid: string;
	mw_nm_emailadres: string;
	mw_fun_is_docent: number;
	mw_fun_is_mentor: number;
};

const somtodayGebruikerTooltip =
	'Op deze pagina zie je alle CumLaude-gebruikers binnen jouw school. Je kunt hier alleen de accounts aanpassen van externe gebruikers. Voor het gebruikersbeheer van Somtoday-gebruikers kun je terecht in Somtoday.';

@Component({
	selector: 'app-gebruikers',
	templateUrl: './gebruikers.component.html',
	styleUrls: ['../account-screen.scss'],
	standalone: true,
	imports: [
		InstantSearchBoxComponent,
		ButtonComponent,
		ToggleButtonComponent,
		TableComponent,
		ConfirmDialogComponent,
		AsyncPipe,
		CssSpinnerComponent,
	],
})
export class GebruikersComponent extends AccountScreen<GebruikerRow> {
	instelling$: Observable<RInstelling>;
	$refresh = new BehaviorSubject<void>(undefined);
	showExportSpinner$: Observable<boolean>;

	// verzamelt succesvolle CREATEs om in 1 toast-melding te kunnen samenvatten
	toegevoegd$ = new Subject<RCumLaudeAccount>();

	@ViewChild('confirmLinkCredentials')
	confirmLinkCredentialsDialog!: TemplateRef<{ data: { email: string } }>;

	@ViewChild('confirmDelete')
	confirmDeleteDialog!: TemplateRef<{ data: { naam: string } }>;

	@ViewChild('confirmResetPassword')
	confirmResetPasswordDialog!: TemplateRef<{ data: { naam: string } }>;

	@ViewChild('confirmResetTotp')
	confirmResetTotpDialog!: TemplateRef<{ data: { naam: string } }>;

	private readonly bron: InstellingBron;

	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService,
		protected bugsnag: BugsnagService,
		protected loadingService: LoadingService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);

		this.bron = userService.bron;

		this.toegevoegd$.pipe(bufferWhen(() => this.toegevoegd$.pipe(debounceTime(100)))).subscribe((accs) => {
			const namenZijn = prettyPeopleList(
				accs.map((acc) => acc.naam),
				4,
				'is',
				'zijn'
			);
			this.toastr.success(`${namenZijn} toegevoegd als gebruiker.`);
		});
		this.showExportSpinner$ = loadingService.shouldShowLoadingIndicator(LoadingType.EXPORT);

		this.instelling$ = this.$refresh.pipe(
			switchMap(() => userService.myAccount$),
			switchMap((account) => this.restService.getInstelling(account.instelling.id!))
		);
	}

	async setTotpRequired(instelling: RInstelling, value: boolean) {
		const updatedInstelling = {
			...instelling,
			totpRequired: value,
		};

		await firstValueFrom(this.restService.putInstelling(updatedInstelling));
		this.$refresh.next();
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<GebruikerRow>> {
		return this.userService.myAccount$.pipe(map((myAccount) => this._makeAccountTableModel(myAccount, accounts)));
	}

	changeBeheer(account: RCumLaudeAccount) {
		const index = account.rollen.indexOf(RRol.BEHEERDER);
		if (index === -1) {
			account.rollen.push(RRol.BEHEERDER);
		} else {
			account.rollen.splice(index, 1);
		}
		this.accountsService.update$.next([account, () => {}, () => {}]);
	}

	openExternAccountToevoegenDialog(currentFilter: string) {
		this.userService.myAccount$.subscribe((myAccount) => {
			const emptyAccount: RCumLaudeAccountX = {
				...this.accountsService.createEmptyAccount(myAccount),
				viaExternDialog: true,
				linkCredentials: false,
			};
			const dialogRef = this.dialog.open<RCumLaudeAccountX>(EditAccountDialogComponent, {
				data: {
					caption: 'Gebruiker toevoegen',
					action: 'Toevoegen',
					model: emptyAccount,
				},
			});
			dialogRef.closed.subscribe((newAccount) => {
				if (newAccount) {
					this.resetFilter(currentFilter);
					this.createAccounts([newAccount]);
				}
			});
		});
	}

	openExternAccountWijzigenDialog(account: RCumLaudeAccount) {
		const dialogRef = this.dialog.open<RCumLaudeAccountX>(EditAccountDialogComponent, {
			data: {
				caption: 'Gebruiker bewerken',
				action: 'Opslaan',
				model: account,
			},
		});
		dialogRef.closed.subscribe((editedAccount) => {
			if (editedAccount) this.updateAccount(editedAccount);
		});
	}

	openInterneAccountsToevoegenDialog(currentFilter: string) {
		combineLatest([
			this.userService.myAccount$,
			this.dataService.getBeheerMedewerkersLijst<MedewerkerAccountInfo>(),
			this.restService.getAccountMedewerkerExternIds(),
		]).subscribe(([myAccount, medewerkersResp, externIdsResp]) => {
			const dialogRef = this.dialog.open<RCumLaudeAccountX[]>(InterneAccountsToevoegenDialogComponent, {
				data: { myAccount, alleMedewerkers: medewerkersResp.rows, medewerkersIdsMetAccount: externIdsResp },
			});
			dialogRef.closed.subscribe((newAccounts) => {
				if (newAccounts) {
					this.resetFilter(currentFilter);
					this.createAccounts(newAccounts);
				}
			});
		});
	}

	createAccounts(newAccounts: RCumLaudeAccountX[]) {
		newAccounts.forEach((account) => {
			this.accountsService.create$.next([
				omit(account, ['viaExternDialog', 'linkCredentials']),
				(receivedAccount) => {
					if (account.viaExternDialog && account.medewerkerExternId)
						this.toastr.success(`Medeweker ${account.naam} is toegevoegd als gebruiker.`);
					else if ((receivedAccount.additionalObjects?.mailer_status_code ?? 0) > 399)
						this.toastr.warning(`${account.naam} is toegevoegd als gebruiker, maar email sturen is mislukt.`);
					else this.toegevoegd$.next(receivedAccount);
				},
				(err) => {
					let message;
					switch (err.error) {
						case ErrorMessageEnum.CREDENTIALS_ALREADY_EXIST: {
							const dialogRef = this.dialog.open(this.confirmLinkCredentialsDialog, {
								data: { email: account.email },
							});
							dialogRef.closed.subscribe((result) => {
								if (result) this.createAccounts([{ ...account, linkCredentials: true }]);
							});
							return;
						}
						case ErrorMessageEnum.CREDENTIALS_ALREADY_EXIST_HERE:
							message = `${account.email} is al in gebruik op deze school.`;
							break;
						case ErrorMessageEnum.EMAIL_INVALID:
							message = `Het emailadres van ${account.naam} (${account.email}) is ongeldig.`;
							break;
						default:
							message = `Kon geen account maken voor ${account.naam} (onbekende fout).`;
							break;
					}
					this.toastr.error(message);
				},
				account.linkCredentials,
			]);
		});
	}

	updateAccount(account: RCumLaudeAccountX) {
		this.accountsService.update$.next([
			omit(account, ['viaExternDialog', 'linkCredentials']),
			() => this.toastr.success(`Wijziging opgeslagen.`),
			(err) => {
				let message;
				switch (err.error) {
					case ErrorMessageEnum.CREDENTIALS_ALREADY_EXIST: {
						const dialogRef = this.dialog.open(this.confirmLinkCredentialsDialog, {
							data: { email: account.email },
						});
						dialogRef.closed.subscribe((result) => {
							if (result) this.updateAccount({ ...account, linkCredentials: true });
						});
						return;
					}
					case ErrorMessageEnum.CREDENTIALS_ALREADY_EXIST_HERE:
						message = `${account.email} is al in gebruik op deze school.`;
						break;
					case ErrorMessageEnum.EMAIL_INVALID:
						message = `Het emailadres ${account.email} is ongeldig.`;
						break;
					default:
						message = `Er ging iets mis bij het opslaan van de wijziging. Probeer later opnieuw.`;
						break;
				}
				this.toastr.error(message);
			},
			account.linkCredentials,
		]);
	}

	deleteAccount(account: RCumLaudeAccount) {
		this.accountsService.delete$.next([
			account,
			() => {
				this.toastr.success(`${account.naam} is verwijderd als gebruiker.`);
			},
			() => {
				this.toastr.error(`Er ging iets mis bij het verwijderen van ${account.naam}. Probeer later opnieuw.`);
			},
		]);
	}

	private _makeAccountTableModel(myAccount: RCumLaudeAccount, accounts: RCumLaudeAccount[]): TableModel<GebruikerRow> {
		const model = createModel(accounts.map(makeGebruikerRow), (row) => row.account.id!);

		if (model.data.some(isEditable)) {
			model.columnDefs = [
				this.getNaamColumn(),
				createColumnDef('account.email', 'E-mailadres'),
				this.getWachtwoordColumn(),
				this.getTotpColumn(),
				this.getBeheerderColumn(myAccount),
				this.getEditColunn(),
				this.getDeleteColumn(myAccount),
			];
		} else {
			model.columnDefs = [this.getNaamColumn(), this.getBeheerderColumn(myAccount)];
		}
		return model;
	}

	getNaamColumn(): ColumnDef<GebruikerRow> {
		const naamColumn = super.getNaamColumn();
		if (this.bron !== InstellingBron.Somtoday) return naamColumn;

		return {
			...naamColumn,
			header: {
				...naamColumn.header,
				getValue: (model: TableModel<GebruikerRow>) => {
					const value = naamColumn.header.getValue(model);
					return {
						text: value,
						tooltip: somtodayGebruikerTooltip,
						icon: 'svg-info',
					};
				},
			},
		};
	}

	private getBeheerderColumn(myAccount: RCumLaudeAccount): ColumnDef<GebruikerRow> {
		const beheerderCol = createColumnDef<GebruikerRow>('beheerder', 'Beheer');
		beheerderCol.body.component = CheckboxCellComponent;
		beheerderCol.body.getValue = ({ account, beheerder }) => ({
			enabled: myAccount.support,
			tooltip: myAccount.support ? '' : 'Neem contact op met de CumLaude Support om beheerdersrechten aan te passen.',
			value: beheerder,
			callback: () => {
				this.changeBeheer(account);
			},
		});
		return beheerderCol;
	}

	private getDeleteColumn(myAccount: RCumLaudeAccount): ColumnDef<GebruikerRow> {
		return this.createActionColumn(
			'delete',
			'',
			'icon-delete',
			({ account }) => {
				const dialogRef = this.dialog.open(this.confirmDeleteDialog, {
					data: { naam: account.naam },
				});
				dialogRef.closed.subscribe((result) => {
					if (result) this.deleteAccount(account);
				});
			},
			({ account, editable }) => editable && account.id !== myAccount.id
		);
	}

	private getEditColunn(): ColumnDef<GebruikerRow> {
		return this.createActionColumn(
			'edit',
			'',
			'icon-edit',
			({ account }) => {
				this.openExternAccountWijzigenDialog(account);
			},
			({ account }) => Boolean(account.email && !account.medewerkerExternId)
		);
	}

	private getTotpColumn(): ColumnDef<GebruikerRow> {
		const totpCol = createColumnDef<GebruikerRow>('totpActive', '2FA');
		totpCol.body.component = ActionCellComponent;
		totpCol.body.getValue = ({ account }) => (account.totpActive ? this.getTotpColumnActionCellProperties(account) : undefined);
		return totpCol;
	}

	private getWachtwoordColumn(): ColumnDef<GebruikerRow> {
		const wachtwoordCol = createColumnDef<GebruikerRow>('password', 'Wachtwoord');
		wachtwoordCol.body.component = ActionCellComponent;
		wachtwoordCol.body.getValue = ({ account, editable }) => (editable ? this.getWachtwoordColumnActionCellProperties(account) : undefined);
		return wachtwoordCol;
	}

	private getTotpColumnActionCellProperties(account: RCumLaudeAccount): ActionCellProperties {
		return {
			title: 'Reset',
			callback: () => {
				const dialogRef = this.dialog.open(this.confirmResetTotpDialog, {
					data: { naam: account.naam },
				});
				dialogRef.closed.subscribe((result) => {
					if (result) {
						this.restService
							.resetAccountTotp(account)
							.pipe(
								catchError((err) => {
									this.bugsnag.notify(err);
									this.toastr.error(`Er ging iets mis bij het resetten van de tweestapsverificatie. Probeer later opnieuw.`);
									return of();
								})
							)
							.subscribe(() => {
								this.accountsService.updateLocal$.next({ ...account, totpActive: false });
								this.toastr.success(`De tweestapsverificatie van ${account.naam} is gereset.`);
							});
					}
				});
			},
		};
	}

	private getWachtwoordColumnActionCellProperties(account: RCumLaudeAccount): ActionCellProperties {
		return {
			title: 'Reset',
			callback: () => {
				const dialogRef = this.dialog.open(this.confirmResetPasswordDialog, {
					data: { naam: account.naam },
				});
				dialogRef.closed.subscribe((result) => {
					if (result) {
						this.restService
							.resetAccountPassword(account)
							.pipe(
								catchError((err) => {
									this.bugsnag.notify(err);
									this.toastr.error(`Er ging iets mis bij het resetten van het wachtwoord. Probeer later opnieuw.`);
									return of();
								})
							)
							.subscribe(() => {
								this.toastr.success(`Het wachtwoord van ${account.naam} is gereset.`);
							});
					}
				});
			},
		};
	}

	async export() {
		const data = await this.generateExportTable();

		try {
			const blob = await firstValueFrom(this.dataService.getTableExport(data));
			saveAs(blob, `CumLaude accounts ${getTimestamp()}.xlsx`);
			this.loadingService.stop(LoadingType.EXPORT);
			this.toastr.success('Export succesvol.');
		} catch (error) {
			console.error(error);
			this.toastr.error('Er ging iets mis bij het exporteren. Probeer opnieuw.');
		}
	}

	private async generateExportTable(): Promise<ExportTable> {
		const vestigingen = await firstValueFrom(this.alleVestigingen$);
		const accounts = await firstValueFrom(this.restService.getAccountsFromFilter({}, [RCumLaudeAccountAdditionalObjectKey.LAATSTE_GEBRUIK]));

		const columns: ExportColumnDef[] = [
			{
				name: 'Naam',
				type: 'string',
			},
			{
				name: 'Afkorting',
				type: 'string',
			},
			{
				name: 'Beheerder',
				type: 'string',
			},
			{
				name: 'Rollen',
				type: 'string',
			},
			{
				name: 'Laatste gebruik',
				type: 'datetime',
			},
			{
				name: 'Vestigingen',
				type: 'string',
			},
		];

		const rows: any[][] = accounts.map((account) => {
			const accountVestigingen = vestigingen
				.filter((vestiging) => account.vestigingen.includes(vestiging.vestigingId))
				.map((vestiging) => vestiging.naam);
			const accountRollen = account.rollen.map((rol) => this.userService.getRolName(rol)).filter((rol) => rol.length > 0);
			return [
				account.naam,
				account.afkorting,
				account.rollen.includes(RRol.BEHEERDER) ? 'Ja' : 'Nee',
				accountRollen.join(', '),
				account.additionalObjects![RCumLaudeAccountAdditionalObjectKey.LAATSTE_GEBRUIK],
				accountVestigingen.join(', '),
			];
		});

		return {
			data: [
				{
					columns: columns,
					rows: rows,
				},
			],
			options: {
				title: 'Beheer > Gebruikers',
				f: new QueryFilterExpression([]),
				attrs: [],
				attrLabels: [],
				orientation: Orientation.PORTRAIT,
			},
		};
	}
}

interface GebruikerRow extends AccountRow {
	beheerder: boolean;
	editable: boolean;
}

function isEditable({ editable }: GebruikerRow): boolean {
	return editable;
}

function makeGebruikerRow(account: RCumLaudeAccount): GebruikerRow {
	const { email, rollen } = account;
	return {
		account,
		beheerder: rollen.includes(RRol.BEHEERDER),
		editable: email !== undefined,
	};
}
