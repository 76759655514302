import { Niveau, Prestatie } from '@cumlaude/metadata';
import { difference, get, intersection, mapValues } from 'lodash-es';
import { Attr, AttrPath } from './data.service';

export const nonBreakingSpace = '\u00a0';

export const nonBreakingHyphen = '\u2011';

export function niveau_afk(niveau: Niveau): string {
	const map: { [n in Niveau]: string } = {
		'te breed': 'Onb',
		OND: 'lj',
		VSO: 'vso',
		PrO: 'p',
		'VMBO b': 'b',
		'VMBO b / k': 'bk',
		'VMBO k': 'k',
		'VMBO k / (g)t': 'k',
		'VMBO (g)t': 't',
		'VMBO (g)t / HAVO': 'th',
		'VMBO (g)t / HAVO / VWO': 'lj',
		'VMBO / HAVO / VWO': 'lj',
		HAVO: 'h',
		'HAVO / VWO': 'hv',
		VWO: 'v',
		//        'International': 'int'
	};
	return map[niveau] ?? 'Onb';
}

export function idu_afk(idu: Prestatie | null, extraKort: boolean): string {
	const normaalMap: { [n in Prestatie]: string } = {
		Afgewezen: 'Afgewezen',
		Afstroom: 'Af',
		Doorstroom: 'Door',
		Doublure: 'Dou',
		Geslaagd: 'Geslaagd',
		Opstroom: 'Op',
		Geen: 'Geen',
	};
	const extraKortMap: { [n in Prestatie]: string } = {
		Afgewezen: 'Afg',
		Afstroom: 'Af',
		Doorstroom: 'Door',
		Doublure: 'Dou',
		Geslaagd: 'Gesl',
		Opstroom: 'Op',
		Geen: 'Geen',
	};
	const map = extraKort ? extraKortMap : normaalMap;
	return idu ? map[idu] : 'Onb';
}

export type AttrMap = {
	[attr in Attr]?: AttrMap | string;
};

const iltOpleidingscodeMap: AttrMap = {
	ilt_abb_profiel: 'Profiel',

	ilt_co_ilt: 'ILT-code',

	ilt_des_opleiding: 'Opleiding omschrijving',

	ilt_is_lwoo: 'LWOO',
	ilt_is_leerwerktraject: 'LWT',
	ilt_is_vavo: 'VAVO',

	ilt_nm_leerweg: 'Leerweg',
	ilt_nm_niveau: 'Niveau',
	ilt_nm_opleiding: 'Opleiding (officieel)',
	ilt_nm_profiel: 'Profiel omschrijving',

	ilt_nr_examenjaar: 'Opleiding examenjaar',
};

const leerlingMap: AttrMap = {
	ll_d_geboortedatum: 'Geboortedatum',
	ll_d_inschrijving: 'Inschrijvingsdatum',
	ll_d_uitschrijving: 'Uitschrijvingsdatum',

	ll_des_uitschrijving: 'Uitschrijvingsreden',
	ll_des_vertrekstatus: 'Vertrekstatus',

	ll_is_apcg: 'APCG',
	ll_is_cohortstatus_tot_volledig: 'Cohortstatus tot volledig',

	ll_nm_achternaam: 'Achternaam',
	ll_nm_basisschooladvies: 'Basisschooladvies (omschrijving)',
	ll_nm_basisschooladvies_duo: 'Basisschooladvies (DUO)',
	ll_nm_basisschooladvies_uni_herzien: 'Basisschooladvies (herzien)',
	ll_nm_basisschooladvies_uni: 'Basisschooladvies',
	ll_nm_geslacht: 'Geslacht',
	ll_nm_leerling: 'Leerling',
	ll_nm_nationaliteit: 'Nationaliteit',
	ll_nm_niveau_lj3: 'Niveau leerjaar 3',
	ll_nm_onderwijssoort_svb: 'Onderwijssoort SvB',
	ll_nm_onderwijssoort_svh: 'Onderwijssoort SvH',
	ll_nm_roepnaam: 'Roepnaam',
	ll_nm_schoolsoort_svb: 'Schoolsoort SvB',
	ll_nm_svh: 'School van herkomst',
	ll_nm_svb: 'School van bestemming',
	ll_nm_voorvoegsel: 'Voorvoegsel',
	ll_nm_woonplaats: 'Woonplaats',

	ll_nr_citototaalscore: 'CITO totaalscore',
	ll_nr_iq_punt: 'IQ-punt',
	ll_nr_leerling: 'Leerlingnummer',
	ll_nr_pc4: 'Postcode',
	ll_nr_uitstroompunt: 'Uitstroompunt',
};

const lesgroepMap: AttrMap = {
	lg_d_schooljaar_va: 'Lesgroep vanaf',
	lg_d_schooljaar_tm: 'Lesgroep tot',

	lg_nm_lesgroep: 'Lesgroep',
	lg_nm_docenten: 'Docenten',
};

const brinVestigingMap: AttrMap = {
	br_co_brin: 'BRIN (vestiging)',

	br_nm_brin_kort: 'Vestiging (officieel)',
};

const vestigingMap: AttrMap = {
	vs_nm_vestiging: 'Vestiging',
};

const postcodeMap: AttrMap = {
	pc_nm_plaats: 'Woonplaats',
	pc_nr_pc4: 'Postcode',
};

const instellingMap: AttrMap = { inst_nm_instelling: 'Instelling' };

const loopbaanMap: AttrMap = {
	lb_abb_leerfase: 'Leerfase (afk)',

	lb_co_brin: 'BRIN',
	lb_co_brin_svh: 'BRIN SvH',

	lb_d_examen: 'Examendatum',
	lb_d_plaatsing_va: 'Plaatsing vanaf',
	lb_d_plaatsing_tm: 'Plaatsing t/m',

	lb_des_plaatsingstatus: 'Plaatsingstatus',

	lb_fk_ll: leerlingMap,
	lb_fk_ilt: iltOpleidingscodeMap,
	lb_fk_vs: vestigingMap,

	lb_is_1okt_plaatsing: '1-oktoberplaatsing',
	lb_is_1febr_plaatsing: '1-februariplaatsing',
	lb_is_apcg: 'APCG',
	lb_is_bekostigd: 'Bekostigbaar',
	lb_is_bevorderd: 'Bevorderd',
	lb_is_examenkandidaat: 'Examenkandidaat',
	lb_is_examenopleiding: 'Examenopleiding',
	lb_is_examenopstroom: 'Examenopstroom',
	lb_is_laatste_plaatsing_sj: 'Laatste plaatsing schooljaar',
	lb_is_nieuwkomer: 'Nieuwkomer',
	lb_is_pl_voorlopig: 'Voorlopige plaatsing',
	lb_is_succesvol: 'Succesvol',

	lb_nm_bekostigingstype: 'Bekostigingstype',
	lb_nm_examenstatus: 'Examenstatus',
	lb_nm_idu: 'Doorstroom',
	lb_nm_instroom: 'Instroom',
	lb_nm_instroommoment: 'Instroommoment',
	lb_nm_instroomtype: 'Instroomtype',
	lb_nm_klas: 'Klas',
	lb_nm_leerfase: 'Leerfase',
	lb_nm_lichting: 'Lichting',
	lb_nm_onderwijssoort: 'Onderwijssoort',
	lb_nm_opleiding: 'Opleiding',
	lb_nm_schooljaar: 'Schooljaar',
	lb_nm_uitstroom: 'Uitstroom',
	lb_nm_uitstroommoment: 'Uitstroommoment',
	lb_nm_uitstroomprofiel_vso: 'Uitstroomprofiel (VSO)',
	lb_nm_uitstroomtype: 'Uitstroomtype',
	lb_nm_vestiging: 'Vestiging',

	lb_nr_leerjaar: 'Leerjaar',
	lb_nr_leerjaar_svb: 'Leerjaar SvB',
};

const doorstroomMap: AttrMap = {
	ds_co_brin_van: 'BRIN',
	ds_co_brin_svh: 'BRIN SvH',

	ds_fk_br_vest_van: brinVestigingMap,
	ds_fk_br_vest_naar: mapValues(brinVestigingMap, (i) => i + ' naar'),

	ds_d_plaatsing_va: 'Plaatsing vanaf',
	ds_d_plaatsing_tm: 'Plaatsing t/m',

	ds_fk_inst: instellingMap,
	ds_fk_lb_van: loopbaanMap,
	ds_fk_lb_naar: mapValues(loopbaanMap, (i) => i + ' volgend schooljaar'),
	ds_fk_lb_vorig_sj: mapValues(loopbaanMap, (i) => i + ' vorig schooljaar'),
	ds_fk_ll: leerlingMap,
	ds_fk_ilt_van: iltOpleidingscodeMap,
	ds_fk_ilt_naar: mapValues(iltOpleidingscodeMap, (i) => i + ' volgend schooljaar'),
	ds_fk_ilt_vorig_sj: mapValues(iltOpleidingscodeMap, (i) => i + ' vorig schooljaar'),
	ds_fk_mw_mentor1: { mw_nm_medewerker: 'Eerste mentor' },
	ds_fk_mw_mentor2: { mw_nm_medewerker: 'Tweede mentor' },
	ds_fk_mw_mentor_indiv: { mw_nm_medewerker: 'Individuele mentor' },
	ds_fk_pc_leerling_van: postcodeMap,
	ds_fk_vs_van: vestigingMap,
	ds_fk_vs_naar: mapValues(vestigingMap, (i) => i + ' (naar)'),

	ds_fun_basisschooladvies_duo: 'Basisschooladvies (DUO)',
	ds_fun_cohortrendement_tot: 'Cohortrendement (tot)',
	ds_fun_cohortrendement_vanaf: 'Cohortrendement (vanaf)',
	ds_fun_cohortstatus_examenopstroom: 'Cohortstatus (examenopstroom)',
	ds_fun_cohortstatus_vanaf: 'Cohortstatus (vanaf)',
	ds_fun_doorstroom_ext: 'Doorstroom',
	ds_fun_is_plaatsing_actueel: 'Actuele plaatsing',
	ds_fun_is_plaatsing_peildatum_1april: '1-aprilplaatsing',
	ds_fun_is_plaatsing_peildatum_1feb: '1-februariplaatsing',
	ds_fun_is_plaatsing_peildatum_1jan: '1-januariplaatsing',
	ds_fun_is_plaatsing_peildatum_1juli: '1-juliplaatsing',
	ds_fun_is_plaatsing_peildatum_1okt: '1-oktoberplaatsing',
	ds_fun_niveau_bbs_van: 'Niveau',
	ds_fun_niveau_volgend_sj: 'Plaatsing leerjaar 3',
	ds_fun_onderwijstype: 'Onderwijstype',
	ds_fun_opstroomrendement: 'Opstroomrendement',
	ds_fun_plaatsing_advies_score: 'Pl./adviesverschil (score)',
	ds_fun_plaatsing_advies_verschil: 'Pl./adviesverschil (niveaus)',
	ds_fun_plaatsing_lj3_advies_score: 'Pl./adviesverschil lj3 (score)',
	ds_fun_plaatsing_lj3_advies_verschil: 'Pl./adviesverschil lj3 (niveaus)',
	ds_fun_overgangmoment: 'Overgangmoment',
	ds_fun_uitstroom_iq_status: 'Uitstroom IQ status',
	ds_fun_verschil_basisschooladvies_van: 'Basisschooladvies',
	ds_fun_verschil_basisschooladvies_duo_van: 'Basisschooladvies (DUO)',
	ds_fun_verschil_basisschooladvies_herzien_van: 'Basisschooladvies (herzien)',

	ds_is_1febr_plaatsing_van: '1-februariplaatsing',
	ds_is_1okt_plaatsing_van: '1-oktoberplaatsing',
	ds_is_apcg_van: 'APCG',
	ds_is_bevorderd: 'Bevorderd',
	ds_is_examenkandidaat_van: 'Examenkandidaat',
	ds_is_examenopstroom_van: 'Examenopstroom',
	ds_is_laatste_plaatsing_sj_van: 'Laatste plaatsing schooljaar',
	ds_is_nieuwkomer_van: 'Nieuwkomer',
	ds_is_prognose: 'Doorstroom is prognose',
	ds_is_succesvol: 'Succesvol',

	ds_nm_bbs_uitzondering_van: 'Bovenbouwsucces uitzondering',
	ds_nm_bekostigingstype_van: 'Bekostigingstype',
	ds_nm_einduitstroom_vso: 'Einduitstroom (VSO)',
	ds_nm_einduitstroom_vso_uitzondering: 'Einduitstroom status',
	ds_nm_idu: 'Doorstroom',
	ds_nm_instroom_in_schooljaar: 'Instroom',
	ds_nm_instroom_van: 'Instroom',
	ds_nm_instroommoment_in_schooljaar: 'Instroommoment',
	ds_nm_instroommoment_van: 'Instroommoment',
	ds_nm_instroomtype_in_schooljaar: 'Instroomtype',
	ds_nm_instroomtype_van: 'Instroomtype',
	ds_nm_klas_van: 'Klas',
	ds_nm_klas_naar: 'Klas (naar)',
	ds_nm_schooljaar_van: 'Schooljaar',
	ds_nm_schooljaar_naar: 'Schooljaar (naar)',
	ds_nm_obs_uitzondering_van: 'Onderbouwsnelheid uitzondering',
	ds_nm_opleiding_van: 'Opleiding',
	ds_nm_opleiding_naar: 'Opleiding volgend schooljaar',
	ds_nm_op_status_van: 'Onderwijspositie status',
	ds_nm_op_uitzondering_van: 'Onderwijspositie uitzondering',
	ds_nm_plaatsing_uitzondering: 'Plaatsing uitzondering',
	ds_nm_prestatieanalyse_vso_uitzondering: 'Prestatieanalyse uitzondering',
	ds_nm_tussentijdse_uitstroom_vso: 'Tussentijdse uitstroom (VSO)',
	ds_nm_tussentijdse_uitstroom_vso_uitzondering: 'Tussentijdse uitstroom status',
	ds_nm_status: 'Doorstroom status',
	ds_nm_uitstroom: 'Uitstroom',
	ds_nm_uitstroom_in_schooljaar: 'Uitstroom',
	ds_nm_uitstroommoment: 'Uitstroommoment',
	ds_nm_uitstroommoment_in_schooljaar: 'Uitstroommoment',
	ds_nm_uitstroomtype: 'Uitstroomtype',
	ds_nm_uitstroomtype_in_schooljaar: 'Uitstroomtype',
	ds_nm_uitstroomprofiel_vso_van: 'Uitstroomprofiel (VSO)',

	ds_nr_leerjaar_van: 'Leerjaar',
	ds_nr_leerjaar_naar: 'Leerjaar (naar)',
	ds_nr_weging: 'Weging',

	ds_map_examenstatus: 'Examenstatus',
	ds_map_examenstatus_met_prognose: 'Examenstatus',
};

const medewerkerMap: AttrMap = {
	mw_abb_geslacht: 'Geslacht',
	mw_abb_medewerker: 'Afkorting',

	mw_d_indienst: 'Datum in dienst',
	mw_d_uitdienst: 'Datum uit dienst',

	mw_fun_dienstjaren: 'Dienstjaren',
	mw_fun_volledige_naam: 'Volledige naam',

	mw_is_ondersteunend: 'Ondersteunend',
	mw_is_onderwijzend: 'Onderwijzend',

	mw_nm_achternaam: 'Achternaam',
	mw_nm_functie: 'Functie',
	mw_nm_medewerker: 'Docent',

	mw_nr_medewerker: 'Nummer',
};

const vakMap: AttrMap = {
	vk_co_vak: 'Vakcode',
	vk_nm_vak: 'Vak (officieel)',
	vk_nm_kernvak: 'Kernvak',
	vk_nm_vak_uni: 'Vak (uniform)',
	vk_nm_vakkengroep: 'Vakkengroep',
};

const cijfersMap: AttrMap = {
	cf_abb_adviescategorie: 'Adviescategorie (afk)',
	cf_abb_kolomkop: 'Cijferkolom kop',
	cf_abb_label: 'Label (afk)',
	cf_abb_onderwijssoort_vak: 'Vak onderwijssoort',
	cf_abb_vak: 'Vak (afk)',

	cf_co_kolom: 'Cijferkolom',

	cf_d_cijfer: 'Datum cijfer',

	cf_des_kolom: 'Naam',

	cf_fk_br_vest: brinVestigingMap,
	cf_fk_ilt: iltOpleidingscodeMap,
	cf_fk_ilt_vorig_sj: mapValues(iltOpleidingscodeMap, (i) => i + ' vorig schooljaar'),
	cf_fk_inst: instellingMap,
	cf_fk_lb: loopbaanMap,
	cf_fk_lb_vorig_sj: mapValues(loopbaanMap, (i) => i + ' vorig schooljaar'),
	cf_fk_lg: lesgroepMap,
	cf_fk_ll: leerlingMap,
	cf_fk_mw_mentor1: { mw_nm_medewerker: 'Eerste mentor' },
	cf_fk_mw_mentor2: { mw_nm_medewerker: 'Tweede mentor' },
	cf_fk_mw_mentor_indiv: { mw_nm_medewerker: 'Individuele mentor leerling' },
	cf_fk_vk: vakMap,
	cf_fk_vs: vestigingMap,

	cf_fks_mw: medewerkerMap,

	cf_fun_basisschooladvies_duo: 'Basisschooladvies (DUO)',
	cf_fun_cijferverschil_se_ce: 'Verschil SE-CE',
	cf_fun_cijferverschil_se_ce_afgerond: 'Verschil SE-CE (afgerond)',
	cf_fun_is_plaatsing_actueel: 'Actuele plaatsing',
	cf_fun_is_plaatsing_peildatum_1april: '1-aprilplaatsing',
	cf_fun_is_plaatsing_peildatum_1feb: '1-februariplaatsing',
	cf_fun_is_plaatsing_peildatum_1jan: '1-januariplaatsing',
	cf_fun_is_plaatsing_peildatum_1juli: '1-juliplaatsing',
	cf_fun_is_plaatsing_peildatum_1okt: '1-oktoberplaatsing',
	cf_fun_kolomgroep: 'Toets',
	cf_fun_nm_vak_uni: 'Vak (uniform)',
	cf_fun_periode: 'Cijferperiode',
	cf_fun_plaatsing_advies_score: 'Pl./adviesverschil (score)',
	cf_fun_plaatsing_advies_verschil: 'Pl./adviesverschil (niveaus)',
	cf_fun_plaatsing_lj3_advies_score: 'Pl./adviesverschil lj3 (score)',
	cf_fun_plaatsing_lj3_advies_verschil: 'Pl./adviesverschil lj3 (niveaus)',
	cf_fun_schooljaar_leerfase: 'Schooljaar',

	cf_is_alternatievenormering: 'Cijfer niveau',
	cf_is_combinatiecijfervak: 'Combinatiecijfervak',
	cf_is_diplomavak: 'Diplomavak',
	cf_is_examendossier: 'In examendossier',
	cf_is_telt_mee_voor_slagen: 'Telt mee voor slagen',
	cf_is_voldoende: 'Cijfer is voldoende',
	cf_is_voortgangsdossier: 'In voortgangsdossier',

	cf_nm_adviescategorie: 'Adviescategorie',
	cf_nm_bijzonderheid: 'Bijzonderheid',
	cf_nm_excijf_uitzondering: 'Examencijfers uitzondering',
	cf_nm_klas: 'Klas',
	cf_nm_kolomtype: 'Cijfertype',
	cf_nm_label: 'Label',
	cf_nm_leerling: 'Leerling',
	cf_nm_lesgroep: 'Lesgroep',
	cf_nm_lesgroep_docenten: 'Lesgroep/docenten',
	cf_nm_lichting: 'Lichting',
	cf_nm_opleiding: 'Opleiding',
	cf_nm_periode: 'Cijferperiode omschrijving',
	cf_nm_schooljaar: 'Schooljaar',
	cf_nm_soort_vakpositie: 'Soort vakpositie',
	cf_nm_vak: 'Vak',
	cf_nm_vestiging: 'Vestiging',

	cf_nr_cijfer: 'Cijfer',
	cf_nr_cijfer_afgerond: 'Cijfer (afgerond)',
	cf_nr_cijfer_ce: 'Bijbehorend CE-cijfer',
	cf_nr_cijfer_eind: 'Bijbehorend eindcijfer',
	cf_nr_cijfer_vorig_sj: 'Cijfer vorig jaar',
	cf_nr_kolom: 'Cijferkolom volgnummer',
	cf_nr_leerjaar: 'Leerjaar',
	cf_nr_leerjaar_vak: 'Vak leerjaar',
	cf_nr_periode: 'Cijferperiode volgnummer',
	cf_nr_tekortpunten: 'Tekortpunten',
	cf_nr_weging: 'Weg.',

	cf_map_examenstatus_met_prognose: 'Examenstatus',
	cf_map_idu: 'Doorstroom',
};

const cijferkolommenMap: AttrMap = {
	ck_abb_kolomkop: 'Cijferkolom kop',

	ck_co_kolom: 'Cijferkolom',

	ck_nm_opleiding: 'Opleiding',
	ck_nm_periode: 'Cijferperiode',
	ck_nm_schooljaar: 'Schooljaar',
	ck_nm_vak: 'Vak',
	ck_nm_vestiging: 'Vestiging',
};

const datumPeriodeMap: AttrMap = {
	per_abb_kwartaal: 'Kwartaal',
	per_abb_weekdag: 'Weekdag',
	per_d_datum: 'Datum',
	per_is_vakantie_midden: 'Vakantie midden',
	per_is_vakantie_noord: 'Vakantie noord',
	per_is_vakantie_zuid: 'Vakantie zuid',
	per_is_weekend: 'Weekend',
	per_nm_schooljaar: 'Schooljaar',
	per_nr_dag: 'Dag van de maand',
	per_nr_datum: 'Datum (numeriek)',
	per_nr_isojaar: 'Jaar (ISO)',
	per_nr_isoweek: 'Weeknummer (ISO)',
	per_nr_jaar: 'Jaar',
	per_nr_maand: 'Maand van het jaar',
	per_nr_weekdag: 'Weekdag (nummer)',
};

const aanwezigheidMap: AttrMap = {
	aw_abb_vak: 'Vak (afk)',

	aw_d_datum: datumPeriodeMap,

	aw_fk_br_vest: brinVestigingMap,
	aw_fk_ilt: iltOpleidingscodeMap,
	aw_fk_inst: instellingMap,
	aw_fk_lb: loopbaanMap,
	aw_fk_lg: lesgroepMap,
	aw_fk_ll: leerlingMap,
	aw_fk_vk: vakMap,
	aw_fk_vs: vestigingMap,

	aw_fks_mw: medewerkerMap,

	aw_fun_is_plaatsing_peildatum_1april: '1-aprilplaatsing',
	aw_fun_is_plaatsing_peildatum_1feb: '1-februariplaatsing',
	aw_fun_is_plaatsing_peildatum_1jan: '1-januariplaatsing',
	aw_fun_is_plaatsing_peildatum_1juli: '1-juliplaatsing',
	aw_fun_is_plaatsing_peildatum_1okt: '1-oktoberplaatsing',
	aw_fun_plaatsing_advies_score: 'Pl./adviesverschil (score)',
	aw_fun_plaatsing_advies_verschil: 'Pl./adviesverschil (niveaus)',
	aw_fun_plaatsing_lj3_advies_score: 'Pl./adviesverschil lj3 (score)',
	aw_fun_plaatsing_lj3_advies_verschil: 'Pl./adviesverschil lj3 (niveaus)',

	aw_is_abs_geoorloofd: 'Absentie',
	aw_is_absent: 'Afwezig',

	aw_nm_abs_reden: 'Reden',
	aw_nm_klas: 'Klas',
	aw_nm_schooljaar: 'Schooljaar',
	aw_nm_vak: 'Vak',

	aw_nr_leerjaar: 'Leerjaar',
	aw_nr_lesuur: 'Lesuur',

	aw_ts_begin: 'Begindatum/tijd',
	aw_ts_eind: 'Einddatum/tijd',
};

const lesregistratieMap: AttrMap = {
	lr_d_datum: datumPeriodeMap,

	lr_des_lesregistratie: 'Omschrijving',

	lr_fk_br_vest: brinVestigingMap,
	lr_fk_ilt: iltOpleidingscodeMap,
	lr_fk_inst: instellingMap,
	lr_fk_lb: loopbaanMap,
	lr_fk_lg: lesgroepMap,
	lr_fk_ll: leerlingMap,
	lr_fk_vk: vakMap,
	lr_fk_vs: vestigingMap,

	lr_fks_mw: medewerkerMap,

	lr_fun_geoorloofd: 'Geoorloofd',
	lr_fun_is_plaatsing_peildatum_1april: '1-aprilplaatsing',
	lr_fun_is_plaatsing_peildatum_1feb: '1-februariplaatsing',
	lr_fun_is_plaatsing_peildatum_1jan: '1-januariplaatsing',
	lr_fun_is_plaatsing_peildatum_1juli: '1-juliplaatsing',
	lr_fun_is_plaatsing_peildatum_1okt: '1-oktoberplaatsing',
	lr_fun_nm_vak_uni: 'Vak (uniform)',
	lr_fun_plaatsing_advies_score: 'Pl./adviesverschil (score)',
	lr_fun_plaatsing_advies_verschil: 'Pl./adviesverschil (niveaus)',
	lr_fun_plaatsing_lj3_advies_score: 'Pl./adviesverschil lj3 (score)',
	lr_fun_plaatsing_lj3_advies_verschil: 'Pl./adviesverschil lj3 (niveaus)',

	lr_nm_klas: 'Klas',
	lr_nm_lesregistratie: 'Lesregistratie',
	lr_nm_opleiding: 'Opleiding',
	lr_nm_vak: 'Vak',

	lr_nr_leerjaar: 'Leerjaar',
	lr_nr_lesuur: 'Lesuur',

	lr_ts_begin: 'Begindatum/tijd',
	lr_ts_eind: 'Einddatum/tijd',
};

const onderwijsresultatenMap: AttrMap = {
	or_co_brinvest: 'BRIN (vestiging)',
	or_des_bron: 'Bron',
	or_fkd_no: {
		no_nr_norm: 'Landelijke norm',
		no_nr_apcg_corr_factor: 'APCG-correctiefactor',
		no_nr_instroom_corr_factor: 'Instroom-correctiefactor',
		no_nr_opstroom_corr_factor: 'Opstroom-correctiefactor',
		no_nr_lwoo_corr_factor: 'LWOO-correctiefactor',
	},
	or_nm_indicator: 'Indicator',
	or_nm_soort_onderwijs: 'Niveau',
	or_nm_schooljaar: 'Schooljaar',
	or_nr_score: 'Score totaal',
	or_q_apcg: 'Aantal leerlingen APCG',
	or_q_instroom: 'Aantal leerlingen instroom',
	or_q_lwoo: 'Aantal leerlingen LWOO',
	or_q_leerlingen: 'Aantal leerlingen totaal',
	or_q_opstroom: 'Aantal leerlingen opstroom',
	or_q_vakken: 'Aantal vakken (examencijfers)',
	pj_nr_peiljaar: 'Peiljaar',
};

const prestatieanalyseMap: AttrMap = {
	pv_nm_schooljaar: 'Schooljaar',
	pv_fun_vest_brin: 'Vestiging BRIN',
};

const examencijfersMap: AttrMap = {
	ekc_fk_br_vest: brinVestigingMap,
	ekc_fk_vk: vakMap,

	ekc_nm_bron: 'Bron',
	ekc_nm_cijfertype: 'Cijfertype',
	ekc_nm_niveau: 'Niveau',
	ekc_nm_schooljaar: 'Schooljaar',

	ekc_nr_gem_cijfer: 'Cijfer',
	ekc_nr_q_cijfers: 'Aantal cijfers',
};

const vakkeuzeMap: AttrMap = {
	vkk_abb_vak: 'Vak (afk)',

	vkk_fk_ilt: iltOpleidingscodeMap,
	vkk_fk_lb: loopbaanMap,
	vkk_fk_lg: lesgroepMap,
	vkk_fk_ll: leerlingMap,
	vkk_fk_vk: vakMap,
	vkk_fk_vs: vestigingMap,
	vkk_fk_inst: instellingMap,

	vkk_fun_is_plaatsing_peildatum_1april: '1-aprilplaatsing',
	vkk_fun_is_plaatsing_peildatum_1feb: '1-februariplaatsing',
	vkk_fun_is_plaatsing_peildatum_1jan: '1-januariplaatsing',
	vkk_fun_is_plaatsing_peildatum_1juli: '1-juliplaatsing',
	vkk_fun_is_plaatsing_peildatum_1okt: '1-oktoberplaatsing',
	vkk_fun_nm_vak_uni: 'Vak (uniform)',

	vkk_nm_lesgroep: 'Lesgroep',
	vkk_nm_niveau_vak: 'Niveau vak',
	vkk_nm_schooljaar: 'Schooljaar',
	vkk_nm_soort_vakpositie: 'Soort vakpositie',
	vkk_nm_vak: 'Vak',

	vkk_nr_leerjaar: 'Leerjaar',
	vkk_nr_leerjaar_vak: 'Leerjaar vak',
};

const basisvaardighedenMap: AttrMap = {
	bv_d_afname: 'Datum afname',

	bv_fk_ilt: iltOpleidingscodeMap,
	bv_fk_inst: instellingMap,
	bv_fk_lb: loopbaanMap,
	bv_fk_ll: leerlingMap,
	bv_fk_vs: vestigingMap,

	bv_fun_basisschooladvies_duo: 'Basisschooladvies (DUO)',
	bv_fun_is_plaatsing_peildatum_1april: '1-aprilplaatsing',
	bv_fun_is_plaatsing_peildatum_1feb: '1-februariplaatsing',
	bv_fun_is_plaatsing_peildatum_1jan: '1-januariplaatsing',
	bv_fun_is_plaatsing_peildatum_1juli: '1-juliplaatsing',
	bv_fun_is_plaatsing_peildatum_1okt: '1-oktoberplaatsing',
	bv_fun_referentieniveau_bin: 'Referentieniveau (score)',

	bv_nm_aanbieder: 'Toetsaanbieder',
	bv_nm_leerfase: 'Leerfase',
	bv_nm_referentieniveau: 'Referentieniveau',
	bv_nm_schooljaar: 'Schooljaar',
	bv_nm_toets: 'Toetsnaam',
	bv_nm_toetsmoment: 'Toetsmoment',
	bv_nm_toetsniveau: 'Toetsniveau',
	bv_nm_vaardigheid: 'Basisvaardigheid',

	bv_nr_leerjaar: 'Leerjaar',
	bv_nr_leerling: 'Leerlingnummer',
	bv_nr_referentieniveau: 'Referentieniveau (numeriek)',
	bv_nr_verbetering: 'Verbetering t.o.v. vorige',
};

export function attrLabel(attrPath: AttrPath): string {
	const map: AttrMap = {
		...aanwezigheidMap,
		...basisvaardighedenMap,
		...cijfersMap,
		...cijferkolommenMap,
		...doorstroomMap,
		...examencijfersMap,
		...iltOpleidingscodeMap,
		...lesregistratieMap,
		...loopbaanMap,
		...medewerkerMap,
		...onderwijsresultatenMap,
		...prestatieanalyseMap,
		...vakkeuzeMap,
	};
	let label = get(map, attrPath);
	if (label === undefined) {
		label = `[${attrPath.join('.')}]`;
		console.warn(`Geen label gedefinieerd voor ${label}. Voeg deze toe in labels.ts.`);
	}
	return label;
}

export type DoorstroomDoel = {
	'ds_fk_ilt_naar.ilt_nm_niveau': Niveau | null;
	ds_nr_leerjaar_naar: string | null;
	ds_nm_idu: Prestatie | null;
	ds_fun_brinvest_overgang?: string | null;
};

export function doorstroom_afk(attrs: DoorstroomDoel, extraKort = false): string {
	const { ds_nr_leerjaar_naar, ds_nm_idu, 'ds_fk_ilt_naar.ilt_nm_niveau': nm_niveau_naar } = attrs;
	if (nm_niveau_naar && ds_nr_leerjaar_naar) return niveau_afk(nm_niveau_naar) + ds_nr_leerjaar_naar;
	else if (nm_niveau_naar) return niveau_afk(nm_niveau_naar) + '? ' + idu_afk(ds_nm_idu, extraKort);
	else if (ds_nr_leerjaar_naar) return '?' + ds_nr_leerjaar_naar + ' ' + idu_afk(ds_nm_idu, extraKort);
	else return idu_afk(ds_nm_idu, extraKort);
}

export function roepAchternaam(medewerker: { mw_nm_roepnaam: string; mw_nm_voorvoegsel: string; mw_nm_achternaam: string }) {
	return [medewerker.mw_nm_roepnaam, medewerker.mw_nm_voorvoegsel, medewerker.mw_nm_achternaam].filter(Boolean).join(' ');
}

export function sortLike<T>(input: T[], order: T[]): T[] {
	const known = intersection(order, input);
	const unknown = difference(input, order);
	return [...known, ...unknown];
}
