import { Injectable } from '@angular/core';
import { AppAuthenticationService, Menu } from '@cumlaude/shared-authentication';
import { UserService } from './user.service';
import { removeFromQp } from '@cumlaude/shared-components-menu';

const menus: Menu[] = [
	{
		conditie: (url: string) => !url.startsWith('/oauth') && !url.startsWith('/beheer'),
		menus: [
			{ naam: 'Dashboard', url: '/dashboard' },
			{ naam: 'Onderwijsresultaten', url: '/onderwijsresultaten' },
			{ naam: 'Prestatieanalyse', url: '/prestatieanalyse' },
			{ naam: 'Basisvaardigheden', url: '/basisvaardigheden' },
			{ naam: 'Cijfers', url: '/cijfers' },
			{
				naam: 'Doorstroom',
				url: '/doorstroom',
				subMenuName: 'doorstroom',
				subMenus: [
					{ naam: 'IDU', url: '/doorstroom/idu' },
					{ naam: 'Advies', url: '/doorstroom/advies' },
					{ naam: 'Cohortrendement', url: '/doorstroom/cohort' },
				],
			},
			{ naam: 'Afwezigheid', url: '/afwezigheid' },
			{ naam: 'Leerling', url: '/leerling' },
			{
				naam: 'Details',
				url: '/details',
				subMenuName: 'details',
				subMenus: [
					{ naam: 'Leerling', url: '/details/leerling' },
					{ naam: 'Docent', url: '/details/docent' },
					{ naam: 'Uitzondering', url: '/details/uitzondering' },
				],
			},
		],
	},
	{
		conditie: (url: string) => url.startsWith('/beheer'),
		menus: [
			{ naam: 'Gebruikers', url: '/beheer/gebruikers' },
			{ naam: 'Vestigingen', url: '/beheer/vestigingen' },
			{ naam: 'Rollen', url: '/beheer/rollen' },
			{ naam: 'Rechten', url: '/beheer/rechten' },
			{ naam: 'Cijferinstellingen', url: '/beheer/cijferinstellingen' },
			{ naam: 'Basisvaardigheden', url: '/beheer/basisvaardigheden' },
		],
	},
];

@Injectable({
	providedIn: 'root',
})
export class UserAuthenticationService extends AppAuthenticationService {
	constructor(protected userService: UserService) {
		super();
	}

	clearSessionStorage() {
		sessionStorage.removeItem('rol');
	}

	getOAuthParams() {
		return {};
	}

	isUrlAllowed(url: string): boolean {
		return this.userService.isUrlAllowed(url);
	}

	getMenus(): Menu[] {
		return menus;
	}

	getRemoveFromQp() {
		return removeFromQp;
	}
}
