import { Categorie, modifyAtt } from './exportable';
import { leerling_instroom_uitstroom, leerling_leerling, leerling_vervolgopleiding, leerling_vooropleiding } from './exportable-leerling';
import {
	loopbaan_examen,
	loopbaan_instroom_uitstroom,
	loopbaan_kenmerk,
	loopbaan_plaatsing,
	opleiding_kenmerk,
	opleiding_opleiding,
} from './exportable-loopbaan';

const vakkeuze_doorstroom: Categorie = {
	label: 'Doorstroom',
	atts: [
		{ att: 'vkk_fk_lb.lb_nm_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'vkk_fk_lb.lb_is_bevorderd', example: 'Ja, Nee' },
		{ att: 'vkk_fk_lb.lb_is_succesvol', example: 'Ja, Nee' },
	],
};

const vakkeuze_examen: Categorie = {
	label: 'Examen',
	atts: [...loopbaan_examen.atts.map((att) => modifyAtt(att, 'vkk_fk_lb'))],
};

const vakkeuze_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		...loopbaan_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'vkk_fk_lb')),
		...leerling_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'vkk_fk_ll')),
	],
};

const vakkeuze_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [...loopbaan_kenmerk.atts.map((att) => modifyAtt(att, 'vkk_fk_lb')), ...opleiding_kenmerk.atts.map((att) => modifyAtt(att, 'vkk_fk_ilt'))],
};

const vakkeuze_leerling: Categorie = {
	label: 'Leerling',
	atts: [...leerling_leerling.atts.map((att) => modifyAtt(att, 'vkk_fk_ll'))],
};

const vakkeuze_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [
		...opleiding_opleiding.atts.map((att) => modifyAtt(att, 'vkk_fk_ilt')),
		{ att: 'vkk_fk_lb.lb_nm_opleiding', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'vkk_nr_leerjaar', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'vkk_fk_lb.lb_nm_klas', example: '1V2, 2B3, 3H4, ...' },
		{ att: 'vkk_fk_lb.lb_nm_leerfase', example: 'HAVO 3, VMBO b/k 1, OND 2' },
		{ att: 'vkk_fk_lb.lb_abb_leerfase', example: 'h3, bk1, lj2' },
		{ att: 'vkk_fk_lb.lb_nm_lichting', example: 'VWO 2020-2023, VMBO Basisberoepsgerichte leerweg 2021-2023, ...' },
		{ att: 'vkk_fk_lb.lb_nm_onderwijssoort', example: 'Praktijkonderwijs, VMBO Theoretische leerweg tweetalig, Gymnasium, ...' },
	],
};

const vakkeuze_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'vkk_fk_ll.ll_nm_niveau_lj3', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'vkk_fun_is_plaatsing_peildatum_1april', example: 'Ja, Nee' },
		{ att: 'vkk_fun_is_plaatsing_peildatum_1feb', example: 'Ja, Nee' },
		{ att: 'vkk_fun_is_plaatsing_peildatum_1jan', example: 'Ja, Nee' },
		{ att: 'vkk_fun_is_plaatsing_peildatum_1juli', example: 'Ja, Nee' },
		{ att: 'vkk_fun_is_plaatsing_peildatum_1okt', example: 'Ja, Nee' },
		...loopbaan_plaatsing.atts.map((att) => modifyAtt(att, 'vkk_fk_lb')),
	],
};

const vakkeuze_prestatieanalyse: Categorie = {
	label: 'Prestatieanalyse',
	atts: [{ att: 'vkk_fk_lb.lb_nm_uitstroomprofiel_vso', example: 'Vervolgonderwijs, Arbeidsmarkt, Dagbesteding' }],
};

const vakkeuze_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'vkk_fk_vs.vs_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'vkk_fk_inst.inst_nm_instelling', example: 'CumLaude College' },
		{ att: 'vkk_fk_lb.lb_co_brin', example: '01XX' },
	],
};

const vakkeuze_vak: Categorie = {
	label: 'Vak',
	atts: [
		{ att: 'vkk_nm_lesgroep', example: '1een, h5cnetl, ...' },
		{ att: 'vkk_nm_vak', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'vkk_abb_vak', example: 'entl, wisA, ...' },
		{ att: 'vkk_nr_leerjaar_vak', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'vkk_nm_niveau_vak', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'vkk_fk_vk.vk_nm_vak', label: 'Vak (officieel)', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'vkk_fk_vk.vk_co_vak', example: '11 - 9999' },
		{ att: 'vkk_fk_vk.vk_nm_vakkengroep', example: 'Engels, Frans, Duits; Wis-, Natuur-, Scheikunde en Biologie, ...' },
		{ att: 'vkk_fk_vk.vk_nm_kernvak', example: 'Nederlands, Engels, wiskunde, overige vakken' },
		{ att: 'vkk_fun_nm_vak_uni', example: 'Engels, wiskunde, ...' },
		{ att: 'vkk_nm_soort_vakpositie', example: 'Gemeenschappelijk, Profielvak, Keuzevak, ...' },
	],
};

const vakkeuze_vooropleiding: Categorie = {
	label: 'Vooropleiding',
	atts: [...leerling_vooropleiding.atts.map((att) => modifyAtt(att, 'vkk_fk_ll'))],
};

const vakkeuze_vervolgopleiding: Categorie = {
	label: 'Vervolgopleiding',
	atts: [
		{ att: 'vkk_fk_lb.lb_co_brin_svh', example: '02AA' },
		{ att: 'vkk_fk_lb.lb_nr_leerjaar_svb', example: '1, 2, 3, 4, 5, 6' },
		...leerling_vervolgopleiding.atts.map((att) => modifyAtt(att, 'vkk_fk_ll')),
	],
};

export const vakkeuze_all = [
	vakkeuze_doorstroom,
	vakkeuze_examen,
	vakkeuze_instroom_uitstroom,
	vakkeuze_kenmerk,
	vakkeuze_leerling,
	vakkeuze_opleiding,
	vakkeuze_plaatsing,
	vakkeuze_prestatieanalyse,
	vakkeuze_school,
	vakkeuze_vak,
	vakkeuze_vervolgopleiding,
	vakkeuze_vooropleiding,
];
