import { Categorie, getAtt, modifyAtt } from './exportable';
import { leerling_instroom_uitstroom, leerling_leerling, leerling_vervolgopleiding, leerling_vooropleiding } from './exportable-leerling';
import {
	loopbaan_examen,
	loopbaan_instroom_uitstroom,
	loopbaan_kenmerk,
	loopbaan_plaatsing,
	opleiding_kenmerk,
	opleiding_opleiding,
} from './exportable-loopbaan';

const cijfers_doorstroom: Categorie = {
	label: 'Doorstroom',
	atts: [
		{ att: 'cf_map_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'cf_fk_lb_vorig_sj.lb_nm_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'cf_fk_lb.lb_is_bevorderd', example: 'Ja, Nee' },
		{ att: 'cf_fk_lb.lb_is_succesvol', example: 'Ja, Nee' },
	],
};

const cijfers_examen: Categorie = {
	label: 'Examen',
	atts: [
		{ att: 'cf_nr_cijfer_ce', isFilter: false, example: '1,0 - 10,0' },
		{ att: 'cf_nr_cijfer_eind', isFilter: false, example: '1,0 - 10,0' },
		{ att: 'cf_fun_cijferverschil_se_ce', example: '-2,5; 1,5' },
		{ att: 'cf_nm_excijf_uitzondering', example: 'Niet bekostigbaar, VAVO-opleiding, ...' },
		{ att: 'cf_map_examenstatus_met_prognose', example: 'Geslaagd, Afgewezen, Voorlaatste jaar, ...' },
		{ att: 'cf_is_diplomavak', example: 'Ja, Nee' },
		{ att: 'cf_is_telt_mee_voor_slagen', example: 'Ja, Nee' },
		...loopbaan_examen.atts.filter((att) => getAtt(att) !== 'lb_nm_examenstatus').map((att) => modifyAtt(att, 'cf_fk_lb')),
	],
};

const cijfers_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		...loopbaan_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'cf_fk_lb')),
		...leerling_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'cf_fk_ll')),
	],
};

const cijfers_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [...loopbaan_kenmerk.atts.map((att) => modifyAtt(att, 'cf_fk_lb')), ...opleiding_kenmerk.atts.map((att) => modifyAtt(att, 'cf_fk_ilt'))],
};

const cijfers_leerling: Categorie = {
	label: 'Leerling',
	atts: [
		...leerling_leerling.atts.filter((att) => getAtt(att) !== 'll_nm_leerling').map((att) => modifyAtt(att, 'cf_fk_ll')),
		{ att: 'cf_nm_leerling', example: 'Vries, Lucas de (123456); Jong, Julia de (23456); ...' },
	],
};

const cijfers_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [
		...opleiding_opleiding.atts.map((att) => modifyAtt(att, 'cf_fk_ilt')),
		{ att: 'cf_fk_ilt_vorig_sj.ilt_nm_niveau', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'cf_nm_opleiding', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'cf_fk_lb_vorig_sj.lb_nm_opleiding', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'cf_nr_leerjaar', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'cf_fk_lb_vorig_sj.lb_nr_leerjaar', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'cf_nm_klas', example: '1V2, 2B3, 3H4, ...' },
		{ att: 'cf_fk_lb.lb_nm_leerfase', example: 'HAVO 3, VMBO b/k 1, OND 2' },
		{ att: 'cf_fk_lb.lb_abb_leerfase', example: 'h3, bk1, lj2' },
		{ att: 'cf_fk_lb_vorig_sj.lb_nm_leerfase', example: 'HAVO 3, VMBO b/k 1, OND 2' },
		{ att: 'cf_fk_lb_vorig_sj.lb_abb_leerfase', example: 'h3, bk1, lj2' },
		{ att: 'cf_fk_mw_mentor1.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'cf_fk_mw_mentor2.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'cf_fk_mw_mentor_indiv.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'cf_fk_lb.lb_nm_lichting', example: 'VWO 2020-2023, VMBO Basisberoepsgerichte leerweg 2021-2023, ...' },
		{ att: 'cf_fk_lb.lb_nm_onderwijssoort', example: 'Praktijkonderwijs, VMBO Theoretische leerweg tweetalig, Gymnasium, ...' },
	],
};

const cijfers_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'cf_fun_is_plaatsing_actueel', example: 'Ja, Nee' },
		{ att: 'cf_fun_plaatsing_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'cf_fun_plaatsing_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'cf_fun_plaatsing_lj3_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'cf_fun_plaatsing_lj3_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'cf_fk_ll.ll_nm_niveau_lj3', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'cf_fun_is_plaatsing_peildatum_1april', example: 'Ja, Nee' },
		{ att: 'cf_fun_is_plaatsing_peildatum_1feb', example: 'Ja, Nee' },
		{ att: 'cf_fun_is_plaatsing_peildatum_1jan', example: 'Ja, Nee' },
		{ att: 'cf_fun_is_plaatsing_peildatum_1juli', example: 'Ja, Nee' },
		{ att: 'cf_fun_is_plaatsing_peildatum_1okt', example: 'Ja, Nee' },
		...loopbaan_plaatsing.atts.map((att) => modifyAtt(att, 'cf_fk_lb')),
	],
};

const cijfers_prestatieanalyse: Categorie = {
	label: 'Prestatieanalyse',
	atts: [{ att: 'cf_fk_lb.lb_nm_uitstroomprofiel_vso', example: 'Vervolgonderwijs, Arbeidsmarkt, Dagbesteding' }],
};

const cijfers_resultaat: Categorie = {
	label: 'Resultaat',
	atts: [
		{ att: 'cf_nr_cijfer', example: '1,0 - 10,0' },
		{ att: 'cf_nr_cijfer_afgerond', example: '1 - 10' },
		{ att: 'cf_is_voldoende', example: 'Ja, Nee' },
		{ att: 'cf_is_alternatievenormering', example: 'Ja, Nee' },
		{ att: 'cf_nr_cijfer_vorig_sj', example: '1,0 - 10,0' },
		{ att: 'cf_co_kolom', example: 'netl151, T1, RepT3, SE, ...' },
		{ att: 'cf_abb_kolomkop', example: 'VG, r3, ...' },
		{ att: 'cf_nr_kolom', example: '321, 1, 55, ...' },
		{ att: 'cf_des_kolom', label: 'Cijferkolom omschrijving', example: 'H1 grammatica, opdracht bij hst 7' },
		{ att: 'cf_fun_periode', example: 'P1, P2, ...' },
		{ att: 'cf_nr_periode', example: '1, 2, ...' },
		{ att: 'cf_nm_periode', example: 'P1, P2, ...' },
		{ att: 'cf_nm_kolomtype', example: 'Gemiddelde, SE-cijfer, Toets, ...' },
		{ att: 'cf_is_examendossier', example: 'Ja, Nee' },
		{ att: 'cf_is_voortgangsdossier', example: 'Ja, Nee' },
		{ att: 'cf_nm_label', example: 'Voldoende, Zeer goed, ...' },
		{ att: 'cf_abb_label', example: 'V, ZG, ...' },
		{ att: 'cf_nr_tekortpunten', example: '0, 1, 2, ...' },
		{ att: 'cf_nm_bijzonderheid', example: 'Geen, Niet gemaakt, Vrijstelling, Telt niet mee' },
		{ att: 'cf_abb_adviescategorie', example: 'Niv, Keuze, ...' },
		{ att: 'cf_nm_adviescategorie', example: 'Niveau advies, Vakkeuze advies, ...' },
	],
};

const cijfers_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'cf_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'cf_fk_lb_vorig_sj.lb_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'cf_fk_lb.lb_co_brin', example: '01XX' },
		{ att: 'cf_fk_br_vest.br_co_brin', example: '01XX00' },
		{ att: 'cf_fk_br_vest.br_nm_brin_kort', example: 'Openb SGM CumLaude College' },
		{ att: 'cf_fk_inst.inst_nm_instelling', example: 'CumLaude College' },
	],
};

const cijfers_toets: Categorie = {
	label: 'Toets',
	atts: [{ att: 'cf_nr_weging', label: 'Weging', example: '1, 2, ...' }],
};

const cijfers_vak: Categorie = {
	label: 'Vak',
	atts: [
		{ att: 'cf_fks_mw.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'cf_nm_lesgroep_docenten', example: '1een - JSL, h5cnetl - DKE, ...' },
		{ att: 'cf_nm_lesgroep', example: '1een, h5cnetl, ...' },
		{ att: 'cf_nm_vak', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'cf_abb_vak', example: 'entl, wisA, ...' },
		{ att: 'cf_nr_leerjaar_vak', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'cf_abb_onderwijssoort_vak', example: 'VMBO b, HAVO / VWO, ...' },
		{ att: 'cf_fk_vk.vk_nm_vak', label: 'Vak (officieel)', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'cf_fk_vk.vk_co_vak', example: '11 - 9999' },
		{ att: 'cf_fk_vk.vk_nm_vakkengroep', example: 'Engels, Frans, Duits; Wis-, Natuur-, Scheikunde en Biologie, ...' },
		{ att: 'cf_fk_vk.vk_nm_kernvak', example: 'Nederlands, Engels, wiskunde, overige vakken' },
		{ att: 'cf_fun_nm_vak_uni', example: 'Engels, wiskunde, ...' },
		{ att: 'cf_is_combinatiecijfervak', example: 'Ja, Nee' },
		{ att: 'cf_nm_soort_vakpositie', example: 'Gemeenschappelijk, Profielvak, Keuzevak, ...' },
	],
};

const cijfers_vooropleiding: Categorie = {
	label: 'Vooropleiding',
	atts: [
		{ att: 'cf_fun_basisschooladvies_duo', example: 'PrO, VMBO b, VMBO k, VMBO (g)t, HAVO, VWO, ...' },
		...leerling_vooropleiding.atts.map((att) => modifyAtt(att, 'cf_fk_ll')),
	],
};

const cijfers_vervolgopleiding: Categorie = {
	label: 'Vervolgopleiding',
	atts: [
		{ att: 'cf_fk_lb.lb_co_brin_svh', example: '02AA' },
		{ att: 'cf_fk_lb.lb_nr_leerjaar_svb', example: '1, 2, 3, 4, 5, 6' },
		...leerling_vervolgopleiding.atts.map((att) => modifyAtt(att, 'cf_fk_ll')),
	],
};

export const cijfers_all = [
	cijfers_doorstroom,
	cijfers_examen,
	cijfers_instroom_uitstroom,
	cijfers_kenmerk,
	cijfers_leerling,
	cijfers_opleiding,
	cijfers_plaatsing,
	cijfers_prestatieanalyse,
	cijfers_resultaat,
	cijfers_school,
	cijfers_toets,
	cijfers_vak,
	cijfers_vervolgopleiding,
	cijfers_vooropleiding,
];
